import React from 'react';
import './Modal.css';
import { useNavigate } from 'react-router-dom';

const Modal = ({ isOpen, onClose, title, message, link, button_message }) => {
    const navigate = useNavigate();
    if (!isOpen) return null;

    return (
        <div className="modal-panel-overlay">
            <div className="modal-panel-container">
                <button className="modal-close-button-panel" onClick={onClose}>
                    ×
                </button>
                <h2>{title}</h2>
                <br />
                <p>{message}</p>
                <button className="modal-action-button-panel"
                    onClick={() => {
                        if (link) {
                            window.location.href = link;
                        } else {
                            onClose();
                        }
                    }}>
                    {button_message}
                </button>
            </div>
        </div>
    );
};

export default Modal;
