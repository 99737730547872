import React, { useRef, useState } from "react";
import ProlificData from './ProlificData';
import RespondentData from './RespondenData';
import { updateLogoutModalActive } from "../../redux/slices/formDetails";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { useDispatch } from "react-redux";

const Panel = () => {
    const dispatch = useDispatch();
    const [selectedPanelTab, setselectedPanelTab] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(false); // State to control sidebar collapse
    const navigate = useNavigate();
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const [openLogout, setopenLogout] = useState(false);

    const handelClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);
    useOutsideAlerter(logoutRef, handelClose);

    return (
        <div className='duonutContainer' style={{ display: "flex" }}>
            <div className="sidebar-toggle" onClick={() => setIsCollapsed(!isCollapsed)} style={{ paddingTop: "5vh" }}>
                <i className={`fa ${isCollapsed ? 'fa-times' : 'fa-bars'}`} style={{ color: "#3184c9" }} aria-hidden="true"></i>
            </div>

            {/* Sidebar */}
            <div className={`AIDPN1leftContainer ${isCollapsed ? 'collapsed' : ''}`} style={{ paddingTop: "10vh", fontSize: "17px", minWidth: "15%" }}>
                {/* <div style={{ marginBottom: '1rem', display: "flex", justifyContent: "center" }}>
          <img style={{ width: "auto", height: "4rem", borderRadius: "10px", cursor: "pointer" }}
            onClick={() => window.location = "/home"}
            src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />
        </div> */}
                {/* {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                    (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                        <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                    </div>} */}


                <div className='AIDPN1tabName' onClick={() => navigate("/profile")}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    Account Settings
                </div>
                {/* <div className='AIDPN1tabName' onClick={() => { dispatch(toggleUserSettingModalDuonut(true)); }}
        // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
        >
          Duonut Mapping
        </div> */}
                {/* {isDuonut &&  */}
                <div className='AIDPN1tabName' onClick={() => navigate("/myduonuts")}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    My Duonuts
                </div>
                <div className='AIDPN1tabName' onClick={() => navigate("/credit")}
                // style={selectedPage === 5 ? { fontWeight: "600", backgroundColor: "#455c6e" } : {}}
                >
                    Credits
                </div>
                <div className='AIDPN1tabName' onClick={() => navigate("/paneldata")}
                    style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    Panels
                </div>

                {/* <div className='AIDPN1tabName' onClick={() => navigate("/aidata?selected=5")}
        // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
        >
          Credits
        </div> */}
                {/* } */}
                <div ref={logoutRef} style={{
                    marginLeft: "10px", display: "flex", justifyContent: "center", textAlign: "center", position: "absolute", bottom: "10px", flexDirection: "column"
                }}>
                    {/* <div className='AIDPN1tabName' onClick={() => window.open("https://duonut.com/contact", "_blank")}>
            Support
          </div> */}
                    <div style={{ cursor: "pointer" }} onClick={() => setopenLogout(!openLogout)}>

                        <div style={{ fontWeight: "600", paddingBottom: "4px" }}>
                            {localStorage.getItem("userName") !== "null" ? localStorage.getItem("userName") : ""} &nbsp; &nbsp; &nbsp;
                            {openLogout ? (
                                <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setopenLogout(false);
                                    }}
                                ></i>
                            ) : (
                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                            )}
                        </div>
                        <div style={{ fontSize: "0.8rem" }}>{localStorage.getItem("userEmail")}</div>
                    </div>
                    {openLogout && (
                        <div className='open-logout'>
                            <ul style={{ textDecoration: "none", width: "100%" }}>
                                <li>
                                    <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                        <i className="fa fa-credit-card" aria-hidden="true"></i>&nbsp; Pricing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                        <i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp; Support
                                    </a>
                                </li>
                                {/* Logout */}
                                <li onClick={() => dispatch(updateLogoutModalActive(true))}>
                                    <i className="fa fa-sign-out" aria-hidden="true"></i> &nbsp; Log Out
                                </li>
                            </ul>
                        </div>
                    )}
                </div>

            </div>




            <div style={{
                display: "flex", flexDirection: "column", width: "90%", paddingTop: "8vh",paddingLeft:"2vw"
            }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedPanelTab === 1 ? "700" : "400" }}
                        onClick={() => setselectedPanelTab(1)}>
                        {selectedPanelTab === 1 && <div className="navbar-selected-line"></div>}
                        Prolific </div>
                    <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedPanelTab === 2 ? "700" : "400" }}
                        onClick={() => setselectedPanelTab(2)}>
                        {selectedPanelTab === 2 && <div className="navbar-selected-line"></div>}
                        Respondent </div>
                </div>
                {selectedPanelTab === 1 && <>
                    <ProlificData />
                </>}
                {selectedPanelTab === 2 && <>
                    <RespondentData />
                </>}
            </div>
        </div>
    );
}
export default Panel;