import React, { useEffect, useRef, useState } from 'react';
import './PublishButton.css';
import { useSelector, useDispatch } from "react-redux";
import { createDuonutAsync } from '../../redux/slices/DuonutReducer';
import { openModal, showSignInForm } from "../../redux/slices/formDetails";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { editDuonut } from "../../services/activity";
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import ShareModal from '../Modals/ModalBox/ShareModal';
import EmbedModal from '../Modals/ModalBox/EmbedModal';
import QrCodeModal from '../Modals/ModalBox/QrCodeModal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { updatePublishModal, setHistoryUserActivities, setHistoryUserActivitiesIndex } from "../../redux/slices/userActivity";

var shareData = {};
var savingDuonut = true;
const PublishButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const publishref = useRef(null);
    const publishref1 = useRef(null);
    const [paramsid, setparamsid] = useState("");
    const [displayId, setdisplayId] = useState(0);
    const [publishing, setPublishing] = useState(false);
    const [saving, setsaving] = useState(false);
    const { userActivities, selectedUserActivity, selectedComponent, historyUserActivities } = useSelector((state) => state.userActivity);
    const { settings, title, description, isPublic, password, published, slack_integration, hubspot_integration,
        gmail_integration, scheduled_workflow, google_sheet_integration, lock
    } = useSelector(state => state.duonutSettings);
    const { isLoggedIn } = useSelector(state => state.user);
    const location = useLocation();
    const { viewer_sliding, show_viewer_vertical, show_leaderboard, show_startpage, show_like_button, show_share_button, show_recommendation, show_progressbar, show_custom_button,
        custom_button_message, custom_button_url, show_endpage, leaderboard_title, leaderboard_description, leaderboard_fontcolor,
        leaderboard_bgcolor, coupon_code, startpage_text, startpage_text2, referral, show_location, show_feedback, show_report,
        startpage_title, startpage_description, startpage_fontcolor, startpage_bgcolor, keywords, base_color,
        show_confetti, play_audio, redirect_url, is_duonut_close_date, duonut_close_date, is_duonut_close_limit,
        duonut_close_limit, show_animation, coverPhoto, audio, slide_logo, base_bgimage, leaderboard_logo, startpage_logo, coupon_code_file,
        slide_logo_alignment, open_ended_conversation_limit } = useSelector((state) => state.duonutSettings.settings);


    const [menuOpen, setMenuOpen] = useState(false);
    const [display_id, setdisplay_id] = useState("");
    const [qrcode, setqrcode] = useState("");
    const [duonut_name, setduonut_name] = useState("");
    const [showShareModal, setshowShareModal] = useState(false)
    const [showEmbedModal, setshowEmbedModal] = useState(false)
    const [showQrCode, setshowQrCode] = useState(false)

    // to close duonut options menu
    const hamburgermenuRef = useRef(null)
    const handleCloseMenu = () => setMenuOpen(false)
    useOutsideAlerter(hamburgermenuRef, handleCloseMenu);

    const handleClick = () => dispatch(openModal());

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);

    let interval1 = null;
    useEffect(() => {
        if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
            interval1 = setInterval(() => {
                // console.log("undoredoduonutToEdit")
                clearInterval(interval1);

                ///////////undo redo////////////////////////
                if (JSON.stringify(historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex]) !== JSON.stringify(userActivities)) {
                    const newHistory = historyUserActivities.historyActivity.slice(0, historyUserActivities.historyActivityIndex + 1);
                    const updatedHistory = [...newHistory, userActivities];
                    // console.log("undoredoduonutToEdit", updatedHistory);

                    dispatch(setHistoryUserActivities(updatedHistory));
                    dispatch(setHistoryUserActivitiesIndex(updatedHistory.length - 1));
                    ////////////////////////////////////
                }
            }, 3000);
            return () => {
                clearInterval(interval1)
            };
        }
    }, [userActivities]);

    let interval = null;
    useEffect(() => {
        if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
            interval = setInterval(() => {
                // console.log("duonutToEdit")
                clearInterval(interval);
                setsaving(true);

                let reqBody = {};
                reqBody.masters_data = [...userActivities];
                reqBody.duonut_setting = settings;
                // console.log("reqBody", reqBody);
                let duonut_id = {
                    name: title,
                    description: description,
                    password: password,
                    visibility: isPublic,
                    slack_integration: slack_integration,
                    hubspot_integration: hubspot_integration,
                    gmail_integration: gmail_integration,
                    scheduled_workflow: scheduled_workflow,
                    google_sheet_integration: google_sheet_integration,
                    lock: lock,
                };
                reqBody.duonut_id = duonut_id;

                let editReqBody = { id: paramsid, ...reqBody.duonut_id, masters_data: reqBody.masters_data, duonut_setting: reqBody.duonut_setting };

                if (savingDuonut) {
                    // console.log('EDITING -----', savingDuonut, localStorage.getItem("userEmail"));
                    savingDuonut = false;

                    editDuonut(paramsid, editReqBody, (err, res) => {
                        clearInterval(interval);
                        setsaving(false);
                        if (err) {
                            console.log(err.response);
                            setsaving(false);
                            // dispatch(updateIsSaved());
                            savingDuonut = true;
                            return;
                        }
                        // dispatch(updateIsSaved());
                        setdisplay_id(res?.data?.display_id);
                        setduonut_name(res?.data?.name);
                        setqrcode(res?.data?.qrcode);
                        savingDuonut = true;
                        // alert(`${duonutToEdit.name} Duonut edited successfully!`);
                    });
                }

            }, 4000);
            return () => {
                clearInterval(interval)
                // clearTimeout(timeout)
            };
        }
    }, [userActivities, description, title, isPublic, coverPhoto, show_viewer_vertical, viewer_sliding,
        show_leaderboard, show_startpage, show_like_button, show_share_button,
        show_recommendation, show_progressbar, show_custom_button, password, slide_logo_alignment,
        custom_button_message, custom_button_url, leaderboard_title, show_endpage,
        leaderboard_description, leaderboard_fontcolor, leaderboard_bgcolor, slide_logo, base_bgimage,
        leaderboard_logo, startpage_logo, coupon_code, audio, coupon_code_file, startpage_text, startpage_text2, referral,
        show_location, show_feedback, show_report, startpage_title, keywords, slack_integration, hubspot_integration,
        gmail_integration, scheduled_workflow, google_sheet_integration, lock,
        startpage_description, startpage_fontcolor, startpage_bgcolor, base_color, show_confetti, play_audio,
        redirect_url, is_duonut_close_date, duonut_close_date, is_duonut_close_limit, duonut_close_limit,
        show_animation, open_ended_conversation_limit]);


    const handlePublish = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return;
        }
        publishref1.current.click();

        setPublishing(true);

        let reqBody = {};
        reqBody.masters_data = [...userActivities];
        reqBody.duonut_setting = settings;
        // console.log("reqBody", reqBody);
        let duonut_id = {
            name: title,
            description: description,
            password: password,
            visibility: isPublic,
            published: true,
            slack_integration: slack_integration,
            hubspot_integration: hubspot_integration,
            gmail_integration: gmail_integration,
            scheduled_workflow: scheduled_workflow,
            google_sheet_integration: google_sheet_integration,
            lock: lock,
        };
        reqBody.duonut_id = duonut_id;

        let editReqBody = { id: paramsid, ...reqBody.duonut_id, masters_data: reqBody.masters_data, duonut_setting: reqBody.duonut_setting };

        editDuonut(paramsid, editReqBody, (err, res) => {
            // console.log(res.data);
            if (err) {
                console.log(err.response);
                // dispatch(updateIsSaved());
                savingDuonut = true;
                setPublishing(false);
                return;
            }
            // dispatch(updateIsSaved());
            setdisplayId(res?.data?.display_id);
            setduonut_name(res?.data?.name);
            // setqrcode(res?.data?.qrcode);
            if (!published) {
                dispatch(updatePublishModal({ active: true, message: "Yayy! Your Duonut has been created successfully.", displayId: res.data.display_id, duonutName: res.data.name, qrCode: res.data.qrcode, isEdited: true }));
            }
            setPublishing(false);
        });

    }

    const onclickpublish = () => {

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return;
        }
        if (!published) {
            publishref.current.click();
        } else {
            handlePublish();
        }
    }

    const onclickCustomize = () => {
        publishref1.current.click();
        navigate(`/design/${paramsid}/settings`);

    }
    const onclickIntegrate = (num) => {
        publishref1.current.click();
        if (num === 1) {
            navigate(`/design/${paramsid}/integrations?slack=true`);
        } else if (num === 2) {
            navigate(`/design/${paramsid}/integrations?hubspot=true`);
        } else if (num === 3) {
            navigate(`/design/${paramsid}/integrations?gmail=true`);
        }

    }

    const handleHamburgerMenu = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return;
        }
        setMenuOpen(!menuOpen);
        setshowShareModal(false);
        setshowEmbedModal(false);
        setshowQrCode(false);
    };

    const handleShare = () => {
        setMenuOpen(false);
        shareData = {
            display_id: display_id,
            duonut_name: duonut_name,
            password: "",
            message: ""
        }
        setshowShareModal(true);
    }

    const handleEmbedCode = () => {
        setMenuOpen(false);
        shareData = {
            display_id: display_id,
            duonut_name: duonut_name,
            password: "",
            message: ""
        }
        setshowEmbedModal(true);
    }

    const handleQrCode = () => {
        setMenuOpen(false);
        shareData = {
            display_id: display_id,
            duonut_name: duonut_name,
            password: "",
            message: "",
            qrcode: qrcode
        }
        setshowQrCode(true);
    }

    return (<>
        <button ref={publishref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        </button>
        <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: "700px" }}>
                <div className="modal-content PublishModalMain" style={{
                    backgroundColor: "white", color: "black", border: "2px solid gray",
                    borderRadius: "15px", marginTop: "15vh"
                }}>
                    <div className="modal-body">
                        <button ref={publishref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <div className="close-icon icon-button publishCloseIcon" onClick={() => publishref1.current.click()}>
                            <img src="/assets/icons/close.svg" />
                        </div>

                        <div style={{
                            width: "3rem", height: "3rem", borderRadius: '50%', backgroundColor: "white",
                            position: 'absolute', top: "-2rem", left: '50%', transform: 'translateX(-50%)',
                            display: "flex", justifyContent: "center",
                            alignItems: 'center', border: '1px solid rgba(0, 0, 0, .2)'
                        }}>
                            <img style={{ width: "auto", height: "2rem", borderRadius: '2rem' }}
                                src={"/assets/duonut_icon.png"} alt="duonut" loading="lazy" />
                        </div>
                        <br></br>

                        <div style={{ margin: "1vh auto", fontSize: "1.5rem" }}>You can customize your Duonut more or <br></br> directly publish it!</div>
                        <br></br>
                        <br></br>
                        <div className='publish_dashboard_row_container'>
                            <div className='publish_dashboard_row'>
                                {/* <label className='roundCircle_success'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" class="" color="white"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.333 4 6 11.333 2.667 8"></path></svg>
                                </label> */}
                                <label style={{ fontSize: "1.2rem", fontWeight: '600', textAlign: 'left' }}>Customize
                                    <div style={{ fontSize: "0.8rem", fontWeight: '400', paddingTop: '5px' }}>You can customize your Duonut more ahead in Settings.</div>
                                </label>
                            </div>
                            <button className='publish_dashboard_button' onClick={() => { publishref1.current.click(); navigate(`/design/${paramsid}/settings`); }}>Settings</button>
                        </div>
                        <br></br>
                        <div className='publish_dashboard_row_container'>
                            <div className='publish_dashboard_row'>
                                {/* <label className='roundCircle_success'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" class="" color="white"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.333 4 6 11.333 2.667 8"></path></svg>
                                </label> */}
                                <label style={{ fontSize: "1.2rem", fontWeight: '600', textAlign: 'left' }}>Publish
                                    <div style={{ fontSize: "0.8rem", fontWeight: '400', paddingTop: '5px' }}>Publish your Duonut.</div>
                                </label>
                            </div>
                            <button className='publish_dashboard_button' style={{ background: "#0E2C45", color: "white", border: "1px solid #0E2C45" }} onClick={handlePublish}>Publish</button>
                        </div>
                        {/* <div className='publish_dashboard_row_container'>
                            <div className='publish_dashboard_row'>
                                {gmail_integration?.access_key ?
                                    <label className='roundCircle_success'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" class="" color="white"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.333 4 6 11.333 2.667 8"></path></svg>
                                    </label>
                                    : <label className='roundCircle'></label>}
                                <label style={{ fontSize: "1.2rem", fontWeight: '600', textAlign: 'left' }}>Integrate Gmail
                                    <div style={{ fontSize: "0.8rem", fontWeight: '400', paddingTop: '5px' }}>Now you can send the Duonut to users through your email. (optional)</div>
                                </label>
                            </div>
                            <button className='publish_dashboard_button' onClick={() => onclickIntegrate(3)}>Edit</button>
                        </div>

                        <div className='publish_dashboard_row_container'>
                            <div className='publish_dashboard_row'>
                                {slack_integration?.access_key ?
                                    <label className='roundCircle_success'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" class="" color="white"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.333 4 6 11.333 2.667 8"></path></svg>
                                    </label>
                                    : <label className='roundCircle'></label>}
                                <label style={{ fontSize: "1.2rem", fontWeight: '600', textAlign: 'left' }}>You can notify people on Slack
                                    <div style={{ fontSize: "0.8rem", fontWeight: '400', paddingTop: '5px' }}>Notify your users on Slack instantly. (optional)</div>
                                </label>
                            </div>
                            <button className='publish_dashboard_button' onClick={() => onclickIntegrate(1)}>Edit</button>
                        </div>

                        <div className='publish_dashboard_row_container'>
                            <div className='publish_dashboard_row'>
                                {hubspot_integration?.access_key ?
                                    <label className='roundCircle_success'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" class="" color="white"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.333 4 6 11.333 2.667 8"></path></svg>
                                    </label>
                                    : <label className='roundCircle'></label>}
                                <label style={{ fontSize: "1.2rem", fontWeight: '600', textAlign: 'left' }}>Add the entries in your hubspot
                                    <div style={{ fontSize: "0.8rem", fontWeight: '400', paddingTop: '5px' }}>Store all your user data at one common place. (optional)</div>
                                </label>
                            </div>
                            <button className='publish_dashboard_button' onClick={() => onclickIntegrate(2)}>Edit</button>
                        </div> */}

                        {/* <div className='publish_dashboard_row_container'>
                            <div className='publish_dashboard_row'>
                                <label style={{ fontSize: "1.2rem", fontWeight: '600', textAlign: 'left' }}>Additional settings
                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                        onClick={() => { window.open("https://duonut.com/blog/user-guide") }}
                                        data-tooltip-content="User Guide"
                                        data-tooltip-id="publishmodel">
                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                    </span>
                                    <div style={{ fontSize: "0.8rem", fontWeight: '400', paddingTop: '5px' }}> Add your logo, custom CTA, cover page, thank you page & more.</div>
                                </label>
                            </div>
                            <button className='publish_dashboard_button' onClick={() => onclickCustomize()}>Edit settings</button>
                        </div> */}
                        <br></br>
                        <br></br>
                        <ReactTooltip id="publishmodel" place="top" effect="solid"> </ReactTooltip>

                        {/* <button className="customizeButton" style={{ margin: "2vh" }} onClick={() => onclickCustomize()}>Customize</button> */}
                        {/* <button className="customizeButton" style={{ margin: "10px" }} onClick={handlePublish}>Publish</button> */}
                    </div>
                </div>
            </div>
        </div >

        {showShareModal && <ShareModal shareData={shareData} bgcolor={"#3184C9"} />
        }
        {showEmbedModal && <EmbedModal shareData={shareData} bgcolor={"#3184C9"} />}
        {showQrCode && <QrCodeModal shareData={shareData} bgcolor={"#3184C9"} />}

        <button
            className={`PublishBtn `} disabled={publishing || saving ? true : false}
            onClick={() => onclickpublish()}
            data-tip="All changes visible to the world" data-for='publishButton'
            // id={title.trim().length === 0 ? 'disabled' : ''}
            style={{ borderRadius: "5px 0px 0px 5px" }}
        >
            {publishing ? "Publishing..." : !published ? "Publish" : "Published"}
        </button>

        <button onClick={() => handleHamburgerMenu()}
            className={`PublishBtn`}
            style={{ padding: "10px 10px", borderRadius: "0px 5px 5px 0px" }}>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
        </button>

        <div ref={hamburgermenuRef} className={menuOpen ? "hamburgermenu hamburgeropen" : "hamburgermenu"}>
            <div className="hamburgermenuinside">
                {display_id && <a href={`/duonut/${display_id}`} target='_blank' className="hamburgermenuitem" onClick={() => { setMenuOpen(false); }}> &nbsp; View as a user</a>}
                {display_id && <span className="hamburgermenuitem" onClick={() => { handleShare(); }}> &nbsp; Share</span>}
                {display_id && <span className="hamburgermenuitem" onClick={() => { handleEmbedCode(); }}> &nbsp; Get Embed Code</span>}
                {display_id && <span className="hamburgermenuitem" onClick={() => { handleQrCode(); }}> &nbsp; QR Code</span>}
            </div>
        </div>

        {
            published && !publishing && <ReactTooltip id="publishButton" place="top" effect="solid" >
            </ReactTooltip>
        }
    </>
    );

}

export default PublishButton;