import React, { useEffect, useState, useRef } from "react";
import Duonut from "../../Components/Duonut/Duonut";
import { getAllDuonuts, getMyDuonuts } from "../../services/duonut";
import "../AiDataNew/AiDataNew.css";
import "./myduonut.css"
import "swiper/css"
import "swiper/css/scrollbar"
import useHorizontalScroll from '../../hooks/useHorizontalScroll'
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ConfirmDeleteDuonut from "../../Components/Modals/ConfirmDeleteDuonut/ConfirmDeleteDuonut";
import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import { useSelector, useDispatch } from "react-redux";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";
import { useNavigate } from 'react-router-dom';
import DuonutNew from "../../Components/Duonut/DuonutNew";
import DuonutNewGrid from "../../Components/Duonut/DuonutNewGrid";
import { updateLogoutModalActive } from "../../redux/slices/formDetails";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { saveAiNewAsync, saveAiNewAsync1, showAiNewresponse } from "../../redux/slices/AiNewReducer";
import { toggleUserSettingModalDuonut } from "../../redux/slices/UserSettingReducer";

const MyDuonut = ({ isMyDuonuts }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useHorizontalScroll();
  const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
  const [drafts, setDrafts] = useState([])
  const [recentDuonut, setRecentDuonut] = useState([])
  const [duonuts, setDuonuts] = useState([])
  const [filteredDuonuts, setFilteredDuonuts] = useState([])
  const { width, height } = useWindowDimensions();
  const [selectedDuonutTab, setselectedDuonutTab] = useState("1");
  const [isPlaying, setIsPlaying] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [popUpPositionX, setPopUpPositionX] = useState(null)
  const [popUpPositionY, setPopUpPositionY] = useState(null)
  const [showShareModal, setshowShareModal] = useState(false)
  const [showEmbedModal, setshowEmbedModal] = useState(false)
  const [showQrCode, setshowQrCode] = useState(false)
  const [showNuggetsModal, setshowNuggetsModal] = useState(false)
  const [viewType, setViewType] = useState("list");
  const [isCollapsed, setIsCollapsed] = useState(false); // State to control sidebar collapse
  const [gptQuestionInput, setgptQuestionInput] = useState('');
  const [aiDataNub, setaiDataNub] = useState(0);
  const [openLogout, setopenLogout] = useState(false);
  const [dashboardSummary, setdashboardSummary] = useState('');
  const [aiDuonutData, setaiDuonutData] = useState([])


  const handelClose = () => {
    setopenLogout(false);
  }
  const logoutRef = useRef(null);
  useOutsideAlerter(logoutRef, handelClose);

  // console.log(duonuts);
  const fetchDuonut = () => {
    if (isMyDuonuts) {
      getMyDuonuts((err, res) => {
        if (err) console.log(err.response);
        else {
          if (res.data.length === 0) {
            setDrafts([])
            setRecentDuonut([])
            setDuonuts([])
            return
          };
          // console.log('res', res.data);
          const tempdrafts = res.data.filter(item => item.published === false).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
          console.log("tempdrafts", tempdrafts)
          setDrafts(tempdrafts)
          const temprecent = res.data.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))[0];
          console.log("temprecent", temprecent)
          setRecentDuonut([temprecent])
          const publishedDuonuts = res.data.filter(item => item.published === true).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
          console.log("publishedDuonuts", publishedDuonuts)
          setFilteredDuonuts(publishedDuonuts);
          setDuonuts(
            res.data.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
          );
        }
      });
    } else {
      setDuonuts([]);
      // getAllDuonuts(1, (err, res) => {
      //   if (err) console.log(err.response);
      //   else {
      //     setDuonuts(res.data);
      //   }
      // });
    }
  }

  useEffect(() => {
    fetchDuonut()
  }, [isMyDuonuts]);

  const handleChannelClick = () => {
    if (!userProAccess) {
      dispatch(toggleProMessageModalDuonut(true));
      dispatch(updateProMessageModalMessage('workspace access'));
      return null;
    }
    navigate("/workflow");
  };
  // const handelDuonutStatus = () => {
  //   const data = {
  //     duonutId: duonutId,
  //   };
  //   fetch(`${domain}/duonut/duonut_acive_status`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  //   }).then((res) => {
  //     console.log(res);
  //     if (active) {
  //       toast.success(`${name} Duonut status is off`);
  //     } else {
  //       toast.success(`${name} Duonut status is on`);
  //     }
  //     fetchDuonut();
  //   }).catch((e) => {
  //     console.log(e.message, e);
  //   });

  // }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(saveAiNewAsync());
    }
  }, [isLoggedIn]);
  const aiData = useSelector(showAiNewresponse);
  const aiData1 = aiData[0];

  useEffect(() => {
    console.log(aiData1);
    if (aiData1) {
      if (aiData1.empty_message) {
        setaiDuonutData([]);
      } else {
        setaiDuonutData(aiData1);
      }
    }
  }, [aiData1])

  // useEffect(() => {
  //   console.log("hello");
  //   console.log(aiData1);
  //   if (aiData1) {
  //     if (aiData1.empty_message) {
  //       navigate('/home');
  //     } else {
  //       console.log(aiData1);

  //     }
  //   }
  // }, [aiData1, aiDataNub])

  useEffect(() => {
    // dispatch(saveAiNewAsync1()).then((res) => {
    //   console.log(res);
    //   if (res.status === 201) {
    //     // dispatch(saveAiNewAsync1());
    //     // setuserQuestionText("");
    //   }

    // }).catch((e) => {
    //   console.log(e.message, e);
    // });
    if (aiData1) {
      if (aiData1.length > 0) {
        getMyDuonuts((err, res) => {
          if (err) {
            console.log(err.response);
          } else {
            if (res.data.length > 0) {
              setselectedDuonutTab("1"); // Both `aiData1` > 0 and `res` > 0
            } else {
              setselectedDuonutTab("2"); // `aiData1` > 0 but `res` = 0
            }
          }
        });
      } else {
        getMyDuonuts((err, res) => {
          if (err) {
            console.log(err.response);
          } else {
            if (res.data.length > 0) {
              console.log("multipage only")
              setselectedDuonutTab("1"); // `aiData1` = 0 but `res` > 0
            }
            else {
              setselectedDuonutTab("1"); // Both `aiData1` = 0 and `res` = 0
            }
          }
        });
      }
    } else {
      getMyDuonuts((err, res) => {
        if (err) {
          console.log(err.response);
        } else {
          if (res.data.length > 0) {
            console.log("multipage only")
            setselectedDuonutTab("1"); // `aiData1` = 0 but `res` > 0
          }
          else {
            setselectedDuonutTab("1"); // Both `aiData1` = 0 and `res` = 0
          }
        }
      });
    }
  }, [aiData1, navigate]);

  function getRelativeTime(createdAt) {
    const now = new Date();
    const date = new Date(createdAt);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count > 0) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  }

  return (
    <div className='duonutContainer' style={{ display: "flex" }}>
      <div className="sidebar-toggle" onClick={() => setIsCollapsed(!isCollapsed)} style={{ paddingTop: "5vh" }}>
        <i className={`fa ${isCollapsed ? 'fa-times' : 'fa-bars'}`} style={{ color: "#3184c9" }} aria-hidden="true"></i>
      </div>

      {/* Sidebar */}
      <div className={`AIDPN1leftContainer ${isCollapsed ? 'collapsed' : ''}`} style={{ paddingTop: "10vh", fontSize: "17px", minWidth: "15%" }}>
        {/* <div style={{ marginBottom: '1rem', display: "flex", justifyContent: "center" }}>
          <img style={{ width: "auto", height: "4rem", borderRadius: "10px", cursor: "pointer" }}
            onClick={() => window.location = "/home"}
            src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />
        </div> */}
        {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
          (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
            <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
          </div>}


        <div className='AIDPN1tabName' onClick={() => navigate("/profile")}
        // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
        >
          Account Settings
        </div>
        {/* <div className='AIDPN1tabName' onClick={() => { dispatch(toggleUserSettingModalDuonut(true)); }}
        // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
        >
          Duonut Mapping
        </div> */}
        {/* {isDuonut &&  */}
        <div className='AIDPN1tabName' onClick={() => navigate("/myduonuts")}
          style={{ fontWeight: "600", backgroundColor: "#455c6e" }}>
          My Duonuts
        </div>
        <div className='AIDPN1tabName' onClick={() => navigate("/credit")}
        // style={selectedPage === 5 ? { fontWeight: "600", backgroundColor: "#455c6e" } : {}}
        >
          Credits
        </div>
        <div className='AIDPN1tabName' onClick={() => navigate("/paneldata")}
        // style={selectedPage === 6 ? { fontWeight: "600", backgroundColor: "#455c6e" } : {}}
        >
          Panels
        </div>

        {/* <div className='AIDPN1tabName' onClick={() => navigate("/aidata?selected=5")}
        // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
        >
          Credits
        </div> */}
        {/* } */}
        <div ref={logoutRef} style={{
          marginLeft: "10px", display: "flex", justifyContent: "center", textAlign: "center", position: "absolute", bottom: "10px", flexDirection: "column"
        }}>
          {/* <div className='AIDPN1tabName' onClick={() => window.open("https://duonut.com/contact", "_blank")}>
            Support
          </div> */}
          <div style={{ cursor: "pointer" }} onClick={() => setopenLogout(!openLogout)}>

            <div style={{ fontWeight: "600", paddingBottom: "4px" }}>
              {localStorage.getItem("userName") !== "null" ? localStorage.getItem("userName") : ""} &nbsp; &nbsp; &nbsp;
              {openLogout ? (
                <i
                  className="fa fa-angle-left"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    setopenLogout(false);
                  }}
                ></i>
              ) : (
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              )}
            </div>
            <div style={{ fontSize: "0.8rem" }}>{localStorage.getItem("userEmail")}</div>
          </div>
          {openLogout && (
            <div className='open-logout'>
              <ul style={{ textDecoration: "none", width: "100%" }}>
                <li>
                  <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                    <i className="fa fa-credit-card" aria-hidden="true"></i>&nbsp; Pricing
                  </a>
                </li>
                <li>
                  <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                    <i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp; Support
                  </a>
                </li>
                {/* Logout */}
                <li onClick={() => dispatch(updateLogoutModalActive(true))}>
                  <i className="fa fa-sign-out" aria-hidden="true"></i> &nbsp; Log Out
                </li>
              </ul>
            </div>
          )}
        </div>

      </div>
      <div className='duonutContainerBlur' style={{ width: "90%" }}>
        <div className="main" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedDuonutTab === "1" ? "700" : "400" }}
              onClick={() => setselectedDuonutTab("1")}>
              {selectedDuonutTab === "1" && <div className="navbar-selected-line"></div>}
              Multi page </div>
            <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedDuonutTab === "2" ? "700" : "400" }}
              onClick={() => setselectedDuonutTab("2")}>
              {selectedDuonutTab === "2" && <div className="navbar-selected-line"></div>}
              Single Page </div>
          </div>
          <div style={{ display: width > 800 ? "flex" : "none", marginRight: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "2px", fontSize: "20px", marinRight: "10px" }}>
              <i
                className={`fa fa-list-ul ${viewType === "list" ? "active-icon" : ""}`}
                aria-hidden="true"
                onClick={() => setViewType("list")} // Set viewType to "list"
                style={{ cursor: "pointer", marginRight: "10px", border: "1px solid #ccc", padding: "8px", borderRadius: "5px", backgroundColor: viewType === "list" ? "#ccc" : "transparent", }}
              ></i>
              <i
                className={`fa fa-th ${viewType === "grid" ? "active-icon" : ""}`}
                aria-hidden="true"
                onClick={() => setViewType("grid")} // Set viewType to "grid"
                style={{ cursor: "pointer", border: "1px solid #ccc", padding: "8px", borderRadius: "5px", backgroundColor: viewType === "grid" ? "#ccc" : "transparent", }}
              ></i>
            </div>
            <div className="demoBtnNav" style={{}}
              onClick={() => navigate("/home")}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>  ADD NEW </div>
            {/* <div className="btn-primary" style={{ marginLeft: "1rem", marginRight: "7rem" }} onClick={() => handleChannelClick()}> WORKSPACE </div> */}
          </div>
        </div>

        {/* {(
          duonuts.length === 0 && drafts.length === 0 && recentDuonut.length === 0) ? (
          <div className="empty-state" style={{ margin: "4rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
            <p style={{ fontWeight: "bolder", fontSize: "24px" }}>Create your first Duonut!</p>
            <img style={{ width: '60%', marginBottom: "10px" }} src="/assets/create-duonut.png" loading="lazy" />
            <button
              className="demoBtnNav" style={{}}
              onClick={() => navigate("/home")}
            >
              <i className="fa fa-plus" aria-hidden="true"></i> ADD NEW
            </button>
          </div>
        ) : */}
        {/* ( */}
        <>
          {selectedDuonutTab === "1" && recentDuonut.length >= 1 && (
            <>
              <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Recent</div>
              <div className="">
                {recentDuonut.map(duonut => (
                  viewType === "list" ? (
                    <DuonutNew {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
                  ) : (
                    <DuonutNewGrid {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} type="recent" />
                  )
                ))}
              </div>
            </>
          )}

          {selectedDuonutTab === "1" && (
            (duonuts.length === 0) ? (
              <div className="empty-state" style={{ margin: "4rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                <p style={{ fontWeight: "bolder", fontSize: "24px" }}>Create your first Duonut!</p>
                <img style={{ width: '60%', marginBottom: "10px" }} src="/assets/create-duonut.png" loading="lazy" />
                <button
                  className="demoBtnNav" style={{}}
                  onClick={() => navigate("/home")}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> ADD NEW
                </button>
              </div>
            ) : (
              <>
                <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>All Duonuts</div>
                <div className={`duonuts-container ${viewType === "grid" ? "row" : ""}`}>
                  {duonuts.map(duonut => (
                    viewType === "list" ? (
                      <DuonutNew {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
                    ) : (
                      <DuonutNewGrid {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} type="grid" />
                    )
                  ))}
                </div>
              </>)
          )}

          {selectedDuonutTab === "2" && (
            (aiDuonutData.length === 0) ?
              (
                <div className="empty-state" style={{ margin: "4rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                  <p style={{ fontWeight: "bolder", fontSize: "24px" }}>Create your first Duonut!</p>
                  <img style={{ width: '60%', marginBottom: "10px" }} src="/assets/create-duonut.png" loading="lazy" />
                  <button
                    className="demoBtnNav" style={{}}
                    onClick={() => navigate("/home")}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> ADD NEW
                  </button>
                </div>
              ) :
              (
                <div className={`duonuts-container ${viewType === "grid" ? "row" : ""}`}>
                  {aiDuonutData.map((duonut, indx) => (
                    viewType === "list" ? (
                      <div className='duonutNew' style={{ cursor: "auto", margin: "25px 0px 30px 75px", width: "80%" }} >
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid #ccc",
                          background: " white",
                          padding: "20px",
                          borderRadius: " 5px",
                          cursor: "pointer",
                          borderLeft: "5px solid #3184C9"
                        }}
                          onClick={() => { window.open(`/aidata?company=${indx + 1}`) }} >
                          <div style={{ marginRight: "2vw", fontWeight: "bold", fontSize: "17px", width: "250px" }}>{duonut?.ai_data?.websiteData?.company_name ? duonut?.ai_data?.websiteData?.company_name : duonut?.ai_data?.title}</div>
                          {/* <div style={{ marginRight: "2vw", fontSize: "17px", color: "#5e6c72", }}>{formattedDate}</div> */}
                          <div
                            style={{ marginRight: "2vw", fontSize: "17px", color: "#5e6c72", borderRadius: "5px", width: "200px" }}
                            title={`${new Date(duonut.ai_data.created_at).toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}, ${new Date(duonut.ai_data.created_at).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}`} // Full Date and Time Tooltip
                          >
                            Created {getRelativeTime(duonut.ai_data.created_at)} {/* Relative Date */}
                          </div>
                          {/* <div className="right">
                        <div style={{ display: 'flex', alignItems: 'center', width: "100px" }} onClick={e => popUpHandler(e)} ref={menuIconRef} >
                          <i class="fa fa-ellipsis-h" aria-hidden="true" style={{ cursor: 'pointer', fontSize: "20px" }}></i>
                        </div>
                      </div> */}
                        </div>
                      </div>

                    ) : (
                      <div className='duonutNew' style={{ cursor: "auto", margin: "25px 0px 30px 75px", width: viewType === "grid" ? "100%" : "30%" }} >
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid #ccc",
                          background: " white",
                          padding: "20px",
                          borderRadius: " 5px",
                          cursor: "pointer",
                          borderLeft: "5px solid #3184C9"
                        }}
                          onClick={() => { window.open(`/aidata?company=${indx + 1}`) }} >
                          <div>
                            <div style={{ marginRight: "2vw", fontWeight: "bold", fontSize: "17px", marginBottom: "5px" }}>{duonut?.ai_data?.websiteData?.company_name ? duonut?.ai_data?.websiteData?.company_name : duonut?.ai_data?.title}</div>
                            {/* <div style={{ marginRight: "2vw", fontSize: "17px", color: "#5e6c72", }}>{formattedDate}</div> */}
                            <div
                              style={{ marginRight: "2vw", fontSize: "17px", color: "#5e6c72", borderRadius: "5px", marginBottom: "2px" }}
                              title={`${new Date(duonut.ai_data.created_at).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}, ${new Date(duonut.ai_data.created_at).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}`} // Full Date and Time Tooltip
                            >
                              Created {getRelativeTime(duonut.ai_data.created_at)} {/* Relative Date */}
                            </div>
                          </div>
                          {/* <div className="right">
                        <div style={{ display: 'flex', alignItems: 'center', width: "100px" }} onClick={e => popUpHandler(e)} ref={menuIconRef} >
                          <i class="fa fa-ellipsis-h" aria-hidden="true" style={{ cursor: 'pointer', fontSize: "20px" }}></i>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    )
                  ))}
                </div>)
          )}

          {/* {selectedDuonutTab === "2" && drafts.length >= 1 && (
              <>
                <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Drafts</div>
                <div className={`duonuts-container ${viewType === "grid" ? "row" : ""}`}>
                  {drafts.map(duonut => (
                    viewType === "list" ? (
                      <DuonutNew {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
                    ) : (
                      <DuonutNewGrid {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} type="grid" />
                    )
                  ))}
                </div>
              </>
            )} */}
        </>
        {/* )} */}
        {/* {recentDuonut.length >= 1 && <>
          <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Recent</div>
          <div className="duonuts2">
            {recentDuonut.map(duonut => {
              // console.log(duonut)
              return (
                <Duonut {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
              )
            })}
          </div>
        </>}

        {selectedDuonutTab === "1" && filteredDuonuts.length >= 1 && <>
          <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Published</div>
          <div className="duonuts2">
            {filteredDuonuts.map(duonut => {
              // console.log(duonut)
              return (
                <Duonut {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
              )
            })
            }
          </div></>}

        {selectedDuonutTab === "2" && drafts.length >= 1 && <>
          <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Drafts</div>
          <div className="duonuts2">
            {drafts.map(duonut => {
              // console.log(duonut)
              return (
                <Duonut {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
              )
            })
            }
          </div></>} */}

      </div>

      <ConfirmDeleteDuonut setDuonuts={setDuonuts} fetchDuonut={fetchDuonut} />
    </div >
  );
};

export default MyDuonut;
