import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import SingleMultiImageOption from './singleMultiImageOption';

export default function ImageMultiOptionsProperty() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [options, setOptions] = useState([]);
    const [description, setdescription] = useState([]);
    const [description2, setdescription2] = useState([]);
    const [optionsImage, setOptionsImage] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            setOptions(tempElement?.elementAttributes?.options);
            setOptionsImage(tempElement?.elementAttributes?.optionsImage);
            setdescription(tempElement?.elementAttributes?.description);
            setdescription2(tempElement?.elementAttributes?.description2);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const addOption = () => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: [...elementAttrib.options, `Option ${elementAttrib.options.length + 1}`],
                    optionsImage: [...elementAttrib.optionsImage, ""],
                    description: [...elementAttrib.description, `description ${elementAttrib.options.length + 1}`],
                    description2: [...elementAttrib.description2, "Price: $ XYZ"],
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (
        <div className="EditorRowContainer" style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <div style={{ margin: "0.5rem 0rem", fontWeight: "700" }}>Options</div>
            {options?.map((item, i) => {
                return <><SingleMultiImageOption item={item} idx={i} description={description[i]} description2={description2[i]} />
                    <hr style={{ width: "100%", margin: "5px 0px" }} /></>
            })}
            <button type='submit' className='addoptionbtn' onClick={() => addOption()} >Add option</button>
        </div>
    )
}

