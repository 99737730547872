import React, { useEffect, useState } from "react";
import './DuonutReports.css';
import { showduonutreport, getduonutreportAsync } from '../../redux/slices/DuonutReportReducer';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx/xlsx.mjs';
import { frontendUrl, backendUrl } from "../../services/constants";
import { editReportApiAsync, deleteReportApiAsync, } from "../../redux/slices/ReportApiReducer";
import Chart from 'react-apexcharts';
import LineChart from "./LineChart";
import LineChart1 from "./LineChart1";
import LineChart2 from "./LineChart2";
import LineChart3 from "./LineChart3";
import LineChart4 from "./LineChart4";
import { toast } from "react-toastify";
import axios from "axios";
import { getQuestionAnswerSummaryAsync, getUserQuestionAnswerSummaryAsync } from "../../redux/slices/AiDuonutNewReducer";
import DuonutAnalytics from "../DuonutAnalytics/DuonutAnalytics";
import { PieChart } from 'react-minimal-pie-chart';

const DuonutReports = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const duonut_id = parseInt(paramsid.id);
    const navigate = useNavigate();
    const { google_sheet_integration } = useSelector(state => state.duonutSettings);

    useEffect(() => {
        dispatch(getduonutreportAsync(duonut_id));
        // console.log("inside", duonut_id);
    }, [duonut_id]);

    const duonutreportData = useSelector(showduonutreport);
    const reportData = duonutreportData[0];
    // console.log(reportData);

    const [tableData, settableData] = useState([]);
    const [duonutName, setduonutName] = useState("");
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [stepNum, setstepNum] = useState(0);
    const [filterNum, setfilterNum] = useState(-1);
    const [filteredItems, setfilteredItems] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [newSelectedAnswer, setNewSelectedAnswer] = useState('');
    const [transformedData, setTransformedData] = useState([]);
    const [columns1, setColumns1] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [selectedDate, setselectedDate] = useState("CreatedAt");
    const [selectedColumn, setselectedColumn] = useState("");
    const [selectedColumn1, setselectedColumn1] = useState("Actual Production");
    const [selectedFilterColumn, setselectedFilterColumn] = useState("");
    const [selectedStep, setselectedStep] = useState("");
    const [dashboardSummary, setdashboardSummary] = useState("");
    const [dashboardAllSummary, setdashboardAllSummary] = useState("");
    const [selectedTab, setselectedTab] = useState(1);
    const [signal, setsignal] = useState("");
    const [allsignal, setallsignal] = useState("");
    const [userGroupData, setuserGroupData] = useState([]);
    const [showConversation, setshowConversation] = useState(-1);
    const [stepData, setstepData] = useState([]);
    const [analysis, setanalysis] = useState([]);
    const [isEmpty, setisEmpty] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [userSummary, setUserSummary] = useState("");
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [isTableVisible, setIsTableVisible] = useState(false);
    // const filteredItems = tableData.filter(
    //     item => item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase()) &&
    //         item.step && item.step.includes(filterNum),
    // );

    // const filteredItems = tableData.filter(item => {
    //     const nicknameMatch = item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase());
    //     const stepMatch = filterNum > 0 ? item.step && item.step.includes(filterNum) : true;
    //     return nicknameMatch && stepMatch;
    // });

    useEffect(() => {
        if (tableData.length > 0) {
            console.log("tableData", tableData)
            var main_filter = tableData.filter(item => {
                const nicknameMatch = item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase());
                const stepMatch = filterNum > 0 ? item.step && item.step.includes(filterNum) : true;
                return nicknameMatch && stepMatch;
            });
            setfilteredItems(main_filter);
        }
    }, [tableData, filterNum, filterText]);

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText || filterNum) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setfilterNum(-1);
            }
        };

        return (
            <div key={filteredItems}>
                <select value={filterNum} className='text_editor_dropdown'
                    style={{ marginRight: "1rem" }}
                    onChange={e => setfilterNum(e.target.value)} >
                    <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled>
                        Filter By Step</option>
                    {Array.from({ length: stepNum }, (_, index) => index).map((item, idx) => {
                        return <option key={idx + 1} value={idx + 1} > {`Step ${idx + 1}`} </option>
                    }
                    )}
                </select>

                <input
                    type="text"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    placeholder="Filter By User"
                    style={{ border: "1px solid black", padding: "6px 10px" }}
                    list="browsers"
                />
                <datalist id="browsers">
                    {filteredItems?.filter((item, index, array) =>
                        array.findIndex((i) => i.nickname === item.nickname) === index)
                        .map((item, i) => {
                            return <option value={item.nickname}></option>
                        })}
                </datalist>
                <button type="button" onClick={handleClear} style={{ padding: "9px 20px", cursor: "pointer" }}>
                    X
                </button>
            </div>
        );
    }, [filterText, resetPaginationToggle, filteredItems]);

    // Function to split and create new entries
    function splitSelectedAnswer(dict) {
        const newDict = {};
        Object.keys(dict).forEach(key => {
            const entry = dict[key];
            const answers = entry.selected_option.split(';').map(option => option.trim());
            answers.forEach((answer, index) => {
                const newKey = `${key}-${index}`;
                newDict[newKey] = {
                    ...entry,
                    selected_option: answer
                };
            });
        });
        return newDict;
    }

    useEffect(() => {
        if (duonutreportData[0]) {
            if (parseInt(localStorage.getItem('userId')) !== parseInt(reportData.created_by)) {
                navigate('/')
                return
            }
            setduonutName(reportData.duonut_name);

            // console.log(reportData.duonut_report_dict);
            // Call the function and get the updated dictionary
            const updatedDict = splitSelectedAnswer(reportData.duonut_report_dict);

            // Output the updated dictionary
            // console.log(updatedDict);

            var tablerows = [];
            // Object.entries(reportData.duonut_report_dict).reverse().map(([key, value]) => {
            //     tablerows.push({ ...value });
            // })
            Object.entries(reportData.duonut_report_dict).map(([key, value]) => {
                tablerows.push({ ...value });
            })

            if (tablerows.length > 0) {
                var largestId = tablerows?.reduce((max, item) => parseInt(item.step) > max ? parseInt(item.step) : max, tablerows[0].step);
                setstepNum(largestId);
                console.log("The largest id is: " + largestId);
            }

            console.log("tablerows", tablerows);
            settableData(tablerows);
        }
    }, [duonutreportData])

    useEffect(() => {
        if (tableData.length > 0) {
            const groupedData = tableData?.reduce((acc, item) => {
                // Find if there's already an entry for this watch_id and nickname
                let existingGroup = acc.find(
                    (group) => group.watch_id === item.watch_id && group.nickname === item.nickname
                );

                if (!existingGroup) {
                    // If no group exists, create a new one
                    existingGroup = {
                        watch_id: item.watch_id,
                        nickname: item.nickname,
                        created_at: item.created_at,
                        records: [],
                    };
                    acc.push(existingGroup);
                }

                // Add the current item to the group's records
                existingGroup.records.push(item);
                return acc;
            }, []);

            console.log("groupedData", groupedData);
            setuserGroupData(groupedData);
        }
    }, [tableData])

    const columns = [
        {
            name: "Date",
            selector: (row) => row.created_at,
            center: true,
            sortable: true,
        },
        // {
        //     name: "id",
        //     selector: (row) => row.id,
        //     center: true,
        // },
        {
            name: "User",
            selector: (row) => row.nickname,
            center: true,
        },
        // {
        //     name: "Query Params",
        //     selector: (row) => row.url_params,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: "Question",
            selector: (row) => row.question,
            center: true,
            wrap: true,
        },
        {
            name: "Selected Answer",
            selector: (row) => row.id === editingRowId ? (
                <input
                    type="text"
                    style={{ border: "1px solid black", padding: "8px 5px", borderRadius: "5px", width: "7rem" }}
                    value={newSelectedAnswer}
                    onChange={handleChange}
                />
            )
                : row.type === "FileUpload" ?
                    (
                        <a href={`${backendUrl}${row.file}`} target="_blank" rel="noopener noreferrer">
                            {`${backendUrl}${row.file}`}
                        </a>
                    ) : row.selected_option,
            center: true,
            wrap: true,
        },
        {
            name: "Result",
            selector: (row) => (row.type === "Mcq") ? row.result ? "Correct" : "Wrong" : "-",
            center: true,
        },
        {
            name: "Step",
            selector: (row) => row.step,
            center: true,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            center: true,
        },
        // {
        //     name: "Location",
        //     selector: (row) => row.location,
        //     center: true,
        //     wrap: true,
        // },
        // {
        //     name: "Status",
        //     selector: (row) => row.status,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: "Actions",
            selector: (row) => (
                <div>
                    {(row.type === "TextField" || row.type === "TextArea" || row.type === "Email" || row.type === "Password" || row.type === "Phone" || row.type === "NumberField" || row.type === "DateField") ?
                        row.id === editingRowId ? (
                            <button className="report_btn-edit" onClick={() => handleSave(row.id)}>Save</button>
                        ) : (
                            <button className="report_btn-edit" onClick={() => handleEdit(row)}>Edit</button>
                        )
                        : <div style={{ width: "4rem", display: "inline-block" }}></div>
                    }
                    <button className="report_btn-delete" onClick={() => handleDelete(row.id)}>Delete</button>
                </div>
            ),
            center: true,
        },
    ]

    const handleSave = (id) => {
        // Handle the save action here (e.g., updating the state or making an API call)
        console.log(`Saving row with id: ${id}, new value: ${newSelectedAnswer}`);

        var reportData = {
            id: id,
            selected_option: newSelectedAnswer,
        }
        dispatch(editReportApiAsync(reportData));

        var main_filter = filteredItems.map(item => {
            if (item.id === id) {
                return { ...item, selected_option: newSelectedAnswer };
            }
            return item;
        });
        setfilteredItems(main_filter);

        setEditingRowId(null);
    };

    const handleChange = (e) => {
        setNewSelectedAnswer(e.target.value);
    };

    const handleEdit = (row) => {
        setEditingRowId(row.id);
        setNewSelectedAnswer(row.selected_option || '');
    };

    const handleDelete = (id) => {
        console.log(`Deleting row with id: ${id}`);
        var reportData1 = {
            id: id
        }
        // console.log(reportData1)
        dispatch(deleteReportApiAsync(reportData1));
        // console.log(filteredItems)

        var main_filter = filteredItems.filter(item => item.id !== id);
        setfilteredItems(main_filter);
    };

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                // console.log("qqq", item[key]);
                var itemData = item[key] ? item[key].toString() : "";
                var itemData = itemData.replace(/,|\n/g, '');;
                if (ctr > 0) result += columnDelimiter;

                result += itemData;
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }


    function downloadCSV(Data) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(Data);
        if (csv == null) return;

        // Add heading to the CSV before the data
        const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        csv = `${heading}\n${csv}`;

        const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.csv` : `${duonutName} reports.csv`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    function downloadXLSX(Data) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(Data);

        // Add heading to the worksheet
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        // XLSX.utils.sheet_add_aoa(ws, [[heading]], { origin: 'A1' });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        // Create a Blob and create a download link
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Utility function to convert string to array buffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    function downloadWordDoc(Data) {
        const heading = "";
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;

        // Create a table in HTML format
        const table = `<h2 style="text-align: center;">Activity Name - ${duonutName}</h2>
                <h3 style="text-align: center;">User - ${Data[0].nickname}</h3>
                    <table style="border-collapse: collapse; width: 100%;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #000; padding: 8px;">User</th>
                                <th style="border: 1px solid #000; padding: 8px;">Question</th>
                                <th style="border: 1px solid #000; padding: 8px;">Result</th>
                                <th style="border: 1px solid #000; padding: 8px;">Selected Answer</th>
                                <th style="border: 1px solid #000; padding: 8px;">Step</th>
                                <th style="border: 1px solid #000; padding: 8px;">Type</th>
                                <th style="border: 1px solid #000; padding: 8px;">Query Params</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${Data.map(item => `<tr>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.nickname}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.question}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.result}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.selected_option}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.step}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.type}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.url_params}</td>
                                            </tr>`).join('')}
                        </tbody>
                      </table>`;

        // Create a Blob containing the table HTML
        const blob = new Blob([heading, table], { type: 'application/msword' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filterText ? `${duonutName} - ${Data[0].nickname} reports.doc` : `${duonutName} reports.doc`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    }



    useEffect(() => {
        if (tableData.length > 0) {
            var transformedData = tableData.reduce((acc, obj) => {
                // Find if an object with the same watch_id and nickname exists in acc array
                let existingObj = acc.find(item => item.WatchId === obj.watch_id && item.NickName === obj.nickname);

                if (!existingObj) {
                    // If no existing object, create a new one with watch_id and nickname
                    existingObj = {
                        WatchId: obj.watch_id,
                        CreatedAt: obj.created_at,
                        NickName: obj.nickname,
                    };
                    acc.push(existingObj);
                }

                // Assign each question and selected_option as properties to existingObj
                existingObj[obj.question] = obj.selected_option;

                return acc;
            }, []);

            console.log(transformedData);
            setTransformedData(transformedData);
        }
    }, [tableData]);

    useEffect(() => {
        if (transformedData.length > 0) {
            // Collect all unique keys across all objects in transformedData
            const allKeys = Array.from(
                new Set(transformedData.flatMap(Object.keys))
            );

            // Generate columns based on these unique keys
            const transformedData1 = allKeys.map(key => ({
                name: key,
                selector: (row) => row[key],
                sortable: true,
                wrap: true,
                center: true,
                omit: false,
            }));

            console.log(transformedData1);

            setColumns1(transformedData1);
        }
    }, [transformedData]);


    const handleToggleColumn = (columnName) => {
        setColumns1(columns1.map(col => col.name === columnName ? { ...col, omit: !col.omit } : col));
    };


    useEffect(() => {
        if (transformedData.length > 0 && selectedColumn) {
            if (selectedFilterColumn) {
                // Group data by the selected filter column (e.g., Shift)
                const groupedData = transformedData.reduce((acc, item) => {
                    const key = item[selectedFilterColumn];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push({
                        x: item[selectedDate],  // Date for x-axis
                        y: parseFloat(item[selectedColumn]) || 0  // Selected Column for y-axis
                    });
                    return acc;
                }, {});

                // Create chart series for each group
                const seriesData = Object.keys(groupedData).map(key => ({
                    name: `${selectedFilterColumn}: ${key}`,
                    data: groupedData[key].sort((a, b) => new Date(a.x) - new Date(b.x)) // Sort by date
                }));

                setLineChartData(seriesData);
            } else {
                // Single series if no filter column is selected
                const singleSeriesData = [{
                    name: selectedColumn,
                    data: transformedData.map(item => ({
                        x: item[selectedDate],  // Date for x-axis
                        y: parseFloat(item[selectedColumn]) || 0  // Selected Column for y-axis
                    })).sort((a, b) => new Date(a.x) - new Date(b.x)) // Sort by date
                }];

                console.log(singleSeriesData)
                setLineChartData(singleSeriesData);
            }
        }
    }, [transformedData, selectedColumn, selectedFilterColumn, selectedDate]);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    const createSheet = async (AccessToken, Data) => {
        console.log("Data", Data)
        try {
            const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${AccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    properties: {
                        title: filename,
                    },
                }),
            });

            if (response.ok) {
                const sheetData = await response.json();
                const spreadsheetId = sheetData.spreadsheetId;
                console.log('Spreadsheet created:', sheetData);
                // Step 2: Generate a comprehensive set of headers
                const headers = Data.reduce((acc, item) => {
                    Object.keys(item).forEach(key => {
                        if (!acc.includes(key)) {
                            acc.push(key);
                        }
                    });
                    return acc;
                }, []);

                // Step 3: Create rows of data that match the headers
                const values = [
                    headers, // Header row
                    ...Data.map(item => headers.map(header => item[header] || '')) // Data rows
                ];

                const range = `Sheet1!A1`; // Start from the first cell
                const updateResponse = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${AccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        range,
                        majorDimension: 'ROWS',
                        values: values,
                    }),
                });

                if (updateResponse.ok) {
                    console.log('Spreadsheet updated with data');
                    toast.success("Export Successfully in Google Sheet.");

                } else {
                    console.error('Error updating spreadsheet with data:', updateResponse.statusText);
                }
            } else {
                console.error('Error creating spreadsheet:', response.statusText);
                if (response && response.status === 401) { // Access token expired
                    try {
                        const newAccessToken = await refreshAccessToken(google_sheet_integration?.refresh_key);
                        await createSheet(newAccessToken, Data);
                    } catch (refreshError) {
                        console.error('Error refreshing access token', refreshError);
                    }
                } else {
                    console.error('Error sending email', response);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    const handelGenerateStepSummary = (step) => {
        //  Extract and concatenate AI conversation messages
        const questionAnswers = tableData
            .filter(item => item.step.startsWith(step))  // Filter only step 2 items
            .map(item => ({
                question: item.question,
                answer: item.selected_option
            }));

        if (questionAnswers.length === 0) {
            setisEmpty("No summary generated as this step doesn’t have any open ended question.");
            return null;
        } else {
            setisEmpty("");
        }

        const stepQuestionAnswers = tableData
            .filter(item => String(item.step) === String(step))  // Filter only step 2 items
            .map(item => ({
                question: item.question,
                answer: item.selected_option
            }));
        console.log("step -Q&A -", stepQuestionAnswers);
        const stepData = tableData
            .filter(item => item.step.startsWith(step))  // Filter only step 2 items
            .map(item => ({
                question: item.question,
                step: item.step,
                selected_option: item.selected_option
            }));
        setstepData(stepData);

        console.log(questionAnswers, stepData);

        var get_ans = {
            questionAnswers: questionAnswers,
            stepQuestionAnswers: stepQuestionAnswers,
        }

        dispatch(getQuestionAnswerSummaryAsync(get_ans)).then((res) => {
            console.log(res);
            if (res?.answer) {
                console.log(res?.answer);
                setdashboardSummary(res?.answer)
            }
            if (res?.signal) {
                setsignal(res?.signal);
            }
            if (res?.analysis) {
                setanalysis(res?.analysis);
            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    const handelGenerateSummary = () => {
        //  Extract and concatenate AI conversation messages
        const questionAnswers = tableData.map(item => ({
            question: item.question,
            selected_option: item.selected_option
        }));

        console.log(questionAnswers);

        var get_ans = {
            questionAnswers: questionAnswers,
        }

        dispatch(getQuestionAnswerSummaryAsync(get_ans)).then((res) => {
            console.log(res);
            if (res?.answer) {
                console.log(res?.answer);
                setdashboardAllSummary(res?.answer)
            }
            if (res?.signal) {
                setallsignal(res?.signal);
            }
        }).catch((e) => {
            console.log(e.message, e);
        });


    }
    const handelGenerateUserSummary = (step) => {
        //  Extract and concatenate AI conversation messages
        // console.log("user summary");
        const questionAnswers = userGroupData[step].records
            .map(item => ({
                question: item.question,
                answer: item.selected_option
            }));
        console.log("User Q&A", questionAnswers)
        // if (questionAnswers.length === 0) {
        //     setisEmpty("No summary generated as this step doesn’t have any open ended question.");
        //     return null;
        // } else {
        //     setisEmpty("");
        // }
        var get_ans = {
            questionAnswers: questionAnswers,
        }

        dispatch(getUserQuestionAnswerSummaryAsync(get_ans)).then((res) => {
            // console.log(res);
            if (res?.answer) {
                // console.log(res?.answer);
                setUserSummary(res?.answer)
            }
            if (res?.signal) {
                setsignal(res?.signal);
            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    // Toggle the visibility of the second div
    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    // Prepare the chart data
    const getChartData = (usersCount) => {
        const totalUsers = analysis.reduce(
            (total, current) => total + current.number_of_unique_users,
            0
        );
        const percentage = (usersCount / totalUsers) * 100;

        return {
            labels: ["Users"],
            datasets: [
                {
                    data: [percentage, 100 - percentage],
                    backgroundColor: ["#36A2EB", "#D3D3D3"],
                },
            ],
        };
    };

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };
    return (
        <div className="chartContainer">

            <div className="LeftSidebarContainer" style={{ borderTop: "2px solid #dbdfe8" }}>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(1)}>Duonut AI Summary
                    {selectedTab === 1 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(2)}>User Responses
                    {selectedTab === 2 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(5)}>Reports
                    {selectedTab === 5 && <div className="selected-line"></div>}
                </div>
                {/* <div className="LeftSidebarTab" onClick={() => setselectedTab(3)}>Form Data
                    {selectedTab === 3 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => setselectedTab(4)}>Graph
                    {selectedTab === 4 && <div className="selected-line"></div>}
                </div> */}
            </div>

            {selectedTab === 4 && <>
                {duonut_id === 149 && <div className="CATTP1templateblur">
                    <div className='CATTP1leftContainer'>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Actual Production")}>
                            <div style={selectedColumn1 === "Actual Production" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Actual Production</div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Prod/hr as per cycle time")}>
                            <div style={selectedColumn1 === "Prod/hr as per cycle time" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Prod/hr as per cycle time </div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Machine Utilization in %")}>
                            <div style={selectedColumn1 === "Machine Utilization in %" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Machine Utilization in %</div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Actual Output Hrs")}>
                            <div style={selectedColumn1 === "Actual Output Hrs" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Actual Output Hrs </div>
                        </div>
                        <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Available M/C hrs")}>
                            <div style={selectedColumn1 === "Available M/C hrs" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Available M/C hrs</div>
                        </div>
                    </div>

                    <div className='CATTP1rightContainer' id="scrollableDiv">
                        <h2 style={{ padding: "2rem" }}>{"Activity Name - " + duonutName}</h2>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}

                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>



                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart3 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart4 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart1 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}

                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                        {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                            <LineChart2 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>

                    </div>
                </div>}

                <br></br><br></br><br></br><br></br>

                <div className="DR1linecharfilter">
                    <div>Select Date Field to use in Chart</div>
                    <select value={selectedDate} className='text_editor_dropdown'
                        onChange={e => setselectedDate(e.target.value)} >
                        <option key={"CreatedAt"} value="CreatedAt" selected>CreatedAt</option>
                        {columns1.map((column, idx) => {
                            if (column.name.toLowerCase().includes('date')) {
                                return (
                                    <option key={idx + 1} value={column.name}>
                                        {column.name}
                                    </option>
                                );
                            }
                            // If the column does not contain a date, do not render anything
                            return null;
                        }
                        )}
                    </select>
                </div>
                <br></br>

                <div className="DR1linecharfilter">
                    <div>Select Number Field to show in Chart</div>
                    <select value={selectedColumn} className='text_editor_dropdown'
                        onChange={e => setselectedColumn(e.target.value)} >
                        <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                            Select Field</option>
                        {columns1.map((column, idx) => {
                            return <option key={idx + 1} value={column.name} > {column.name} </option>
                        }
                        )}
                    </select>
                </div>
                <br></br>

                <div className="DR1linecharfilter">
                    <div>Select Field to Filter wise show in Chart</div>
                    <select value={selectedFilterColumn} className='text_editor_dropdown'
                        onChange={e => setselectedFilterColumn(e.target.value)} >
                        <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                            Select Field</option>
                        {columns1.map((column, idx) => {
                            return <option key={idx + 1} value={column.name} > {column.name} </option>
                        }
                        )}
                    </select>
                </div>

                <div className="DR1linechart">
                    <Chart
                        type="line"
                        width={800}
                        height={400}

                        series={lineChartData}

                        options={{
                            title: {
                                text: "Graphs",
                                style: { fontSize: 30 }
                            },

                            // colors: ['#f90000'],
                            // theme: { mode: "light" },

                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    format: 'dd MMM', // Customize the date format here
                                    // show: false,
                                },
                                title: {
                                    text: "Date",
                                    // style: { color: "#f90000" }
                                },
                            },

                            yaxis: {
                                title: {
                                    text: selectedColumn,
                                    // style: { color: "#f90000" }
                                },
                            }

                        }}

                    >

                    </Chart>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </>}

            {selectedTab === 2 && <>
                <br></br>
                <br></br>
                <h2>User Responses</h2>
                <div>You can view user-wise feedback for each step of your questionnaire</div>
                <br></br>
                <br></br>
                <div style={{ width: "90vw", margin: "auto" }}>
                    {userGroupData.map((item, i) => (<div style={{
                        // border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px", margin: "10px",
                        background: "#cccccc21"
                    }}>
                        <div key={i} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>{i + 1}</div>
                            {/* <div>{item.created_at}</div> */}
                            <div style={{ color: "#37474F", fontSize: "15px" }}>
                                {new Date(item.created_at).toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                })}
                            </div>
                            <div>{item.nickname}</div>
                            {userSummary && selectedUser === i ? (
                                // Display the signal after summary generation
                                <div style={{ color: allsignal === "positive" ? "green" : "red", display: "flex", alignItems: "center", width: "10rem" }}>
                                    <i
                                        className={
                                            allsignal === "positive"
                                                ? "fa fa-check-circle"
                                                : "fa fa-exclamation-triangle"
                                        }
                                        aria-hidden="true"
                                    ></i>
                                    &nbsp;
                                    {allsignal === "positive" ? "Positive Signal" : "Negative Signal"}
                                </div>
                            ) : (
                                // Display the button while generating or before generation
                                <button
                                    className="AIDPN1button"
                                    style={{ width: "10rem" }}
                                    disabled={selectedUser === i} // Disable button while generating
                                    onClick={() => {
                                        setSelectedUser(i);
                                        setUserSummary("");
                                        handelGenerateUserSummary(i);
                                    }}
                                >
                                    {selectedUser === i ? "Generating..." : "Generate"}
                                </button>
                            )}
                            <label style={{ cursor: "pointer" }} onClick={() => { (showConversation === -1 || showConversation !== i) ? setshowConversation(i) : setshowConversation(-1) }}>
                                <svg aria-hidden="true" style={{ height: "1rem", padding: "0px 10px", verticalAlign: "middle", transform: (showConversation === i) ? "rotate(180deg)" : "rotate(0deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                            </label>
                        </div>
                        {userSummary && selectedUser === i && <div style={{ margin: "1rem 10px", textAlign: "left" }}>
                            <div
                                style={{ borderBottom: " 0.5px solid #ccc", padding: "20px 0px", borderTop: " 0.5px solid #ccc" }}>
                                <b>Summary -</b >{userSummary}
                            </div>

                        </div>}

                        {showConversation === i && (
                            <div style={{ marginTop: "2rem" }}>
                                {item?.records?.map((record, index) => (
                                    <div key={index} style={{ padding: "5px", margin: "5px", textAlign: "left" }} >
                                        <div style={{ color: 'gray' }}>
                                            {/* Step-{record.step}.  {record.question} */}
                                            {`Step-${record.step.includes("ai") ? record.step.replace(/ai\d*/, "Follow up") : record.step}. - ${record.question}`}
                                        </div>
                                        <div style={{ padding: "8px 0px" }}>
                                            Answer - {record.selected_option}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    ))}
                </div>
                <br></br>
                <br></br>
                <div style={{ margin: "0px 10px", borderTop: "1px solid #ccc" }}>
                    <div
                        style={{ cursor: "pointer", fontWeight: "bold", margin: "20px 0px", fontSize: "20px" }}
                        onClick={toggleTableVisibility} >
                        Raw-Form Data &nbsp; <span style={{ fontSize: "24px" }}>{isTableVisible ? <i class="fa fa-angle-up" aria-hidden="true"></i> : <i class="fa fa-angle-down" aria-hidden="true"></i>}</span>
                    </div>

                    {/* Conditionally Render Table */}
                    {isTableVisible && (

                        <DataTable
                            // title={"Submitted Data"}
                            // title={"Raw Data"}
                            columns={columns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            paginationPerPage="50"
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                            fixedHeader
                            subHeader
                            subHeaderComponent={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    {/* Filter on the left */}
                                    <div>{subHeaderComponentMemo}</div>

                                    {/* Export buttons on the right */}
                                    <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                                        {google_sheet_integration?.access_key && (
                                            <button
                                                className="btn-primary"
                                                onClick={() =>
                                                    createSheet(google_sheet_integration?.access_key, filteredItems)
                                                }
                                            >
                                                Export in Google Sheet
                                            </button>
                                        )}
                                        <button
                                            className="btn-primary"
                                            onClick={() => downloadCSV(filteredItems)}
                                        >
                                            Export CSV
                                        </button>
                                        <button
                                            className="btn-primary"
                                            onClick={() => downloadXLSX(filteredItems)}
                                        >
                                            Export Xlsx
                                        </button>
                                        <button
                                            className="btn-primary"
                                            onClick={() => downloadWordDoc(filteredItems)}
                                        >
                                            Export Word
                                        </button>
                                    </div>
                                </div>
                            }
                            persistTableHead
                            highlightOnHover
                        // subHeaderComponent={subHeaderComponentMemo}
                        // persistTableHead
                        // highlightOnHover
                        // actions={<>
                        //     {/* <button className="btn-primary" onClick={() => window.location.open(`duonut/`)} > Add</button> */}
                        //     {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, filteredItems)}>Export in Google Sheet</button>}
                        //     <button className="btn-primary" onClick={() => downloadCSV(filteredItems)}>Export CSV</button>
                        //     <button className="btn-primary" onClick={() => downloadXLSX(filteredItems)}>Export Xlsx</button>
                        //     <button className="btn-primary" onClick={() => downloadWordDoc(filteredItems)}>Export Word</button></>}
                        />
                    )}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </>}

            {
                selectedTab === 1 && <>
                    <br></br>
                    <br></br>
                    <div>
                        <h2>AI generated summary from your customers feedback.</h2>
                        <br></br>
                        <br></br>

                        <div className='AIDPN1DashboardContainerItem' style={{ width: "780px", margin: "1em auto", display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div className="AS1row">
                                    <h3>Here’s what your users have to say.</h3>
                                    <label style={{ textAlign: 'left', fontSize: "14px" }}>View AI generated summary from your customers feedback.</label>
                                </div>
                                {!dashboardAllSummary && <button className='AIDPN1button' style={{ fontSize: "1rem" }} onClick={() => handelGenerateSummary()}>Generate All Steps Summary</button>}
                                {dashboardAllSummary && <>{allsignal === "positive" ? <label style={{ display: "flex", alignItems: "center" }}>
                                    <label style={{ marginRight: "10px", color: "green" }}>
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                    </label>
                                </label>
                                    : allsignal === "negative" ? <label style={{ display: "flex", alignItems: "center" }}>
                                        <label style={{ marginRight: "10px", color: "red" }}>
                                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                        </label>
                                    </label>
                                        : <label></label>}
                                </>}
                            </div>
                            {dashboardAllSummary && <div style={{ textAlign: "left" }}>{dashboardAllSummary}</div>}
                        </div>

                        <br></br>
                        <h3>OR</h3>
                        <br></br>
                        <div>
                            {Array.from({ length: stepNum }, (_, index) => index).map((item, idx) => {
                                return <div style={{
                                    display: "flex", flexDirection: "column", justifyContent: "space-between", width: "780px",
                                    border: "1px solid gray", borderRadius: "5px", padding: "10px", margin: "10px auto"
                                }}>
                                    <div style={{ width: "100%", margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div style={{ fontWeight: "600" }}>Generate step {idx + 1} Summary</div>
                                        {(!dashboardSummary || selectedStep !== idx + 1) && <button className="AIDPN1button" onClick={() => { setselectedStep(idx + 1); setdashboardSummary(""); handelGenerateStepSummary(idx + 1) }}>
                                            {selectedStep === idx + 1 && !isEmpty ? "Generating..." : "Generate"}</button>}
                                        {dashboardSummary && selectedStep === idx + 1 && <>{signal === "positive" ? <label style={{ display: "flex", alignItems: "center" }}>
                                            <label style={{ marginRight: "10px", color: "green" }}>
                                                <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Positive Signal
                                            </label>
                                        </label>
                                            : signal === "negative" ? <label style={{ display: "flex", alignItems: "center" }}>
                                                <label style={{ marginRight: "10px", color: "red" }}>
                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp; Negative Signal
                                                </label>
                                            </label>
                                                : <label></label>}
                                        </>}
                                    </div>
                                    {dashboardSummary && selectedStep === idx + 1 && <div style={{ margin: "1rem 10px", textAlign: "left" }}>
                                        <div style={{ fontWeight: "600", padding: "5px 0px" }}>Question - {stepData.find(item => parseInt(item.step) === parseInt(selectedStep)).question}</div>
                                        <br></br>
                                        <div><b>Summary - </b >{dashboardSummary}</div>
                                        <br></br>
                                        <div>
                                            <b style={{ marginBottom: "10px" }}>Answer Analysis - </b>
                                            {analysis.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    {/* Clickable div */}
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginBottom: "10px",
                                                            fontSize: "16px", // Set a base font size for the chart to be based on
                                                        }}

                                                    >
                                                        {/* User Information Section */}
                                                        <div
                                                            style={{
                                                                cursor: "pointer",
                                                                marginLeft: "10px",
                                                                background: "#f0f0f0",
                                                                borderRadius: "5px",
                                                                padding: "10px",
                                                                flex: "1",
                                                            }}
                                                            onClick={() => toggleExpand(i)}
                                                        >
                                                            <p style={{ display: "flex", alignItems: "center" }}>
                                                                <PieChart
                                                                    data={[
                                                                        {
                                                                            title: "Users",
                                                                            value: item.number_of_unique_users,
                                                                            color: "#337ab7", // Custom color for the pie slice
                                                                        },
                                                                        {
                                                                            title: "Remaining",
                                                                            value:
                                                                                analysis.reduce(
                                                                                    (total, current) => total + current.number_of_unique_users,
                                                                                    0
                                                                                ) - item.number_of_unique_users,
                                                                            color: "#ccc", // Color for the remaining part
                                                                        },
                                                                    ]}
                                                                    lineWidth={55} // Adjust slice width for a thicker visual
                                                                    radius={20} // Adjust the radius for a larger pie chart
                                                                    style={{ width: "50px", height: "50px" }} // Adjust width and height for the container
                                                                    startAngle={90}
                                                                />
                                                                {item.number_of_unique_users} {" "}
                                                                <span style={{ color: "#37474fad" }}>
                                                                    (
                                                                    {Math.round(
                                                                        (item.number_of_unique_users /
                                                                            analysis.reduce(
                                                                                (total, current) => total + current.number_of_unique_users,
                                                                                0
                                                                            )) *
                                                                        100
                                                                    )}
                                                                    %)
                                                                </span>{" "}
                                                                - {item.theme_name}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Expanded section outside the clickable div */}
                                                    {expandedIndex === i && (
                                                        <div
                                                            style={{
                                                                margin: "10px 0px",
                                                                padding: "10px",
                                                                background: "#ffffff",
                                                                border: "1px solid #ddd",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            <p style={{ color: "#337ab7" }}>
                                                                Summary - "{item.overall_summary_of_feedback || item.overall_summary_of_feedback_summary}"
                                                            </p>
                                                            <div style={{ marginTop: "10px" }}>
                                                                User Response - <br />
                                                                {/* {item.respective_feedback_summary.map((feedback, index) => (
                                                                    <p key={index} style={{ margin: "6px 0px", fontStyle: "italic" }}>
                                                                        "{feedback}"
                                                                    </p>
                                                                ))} */}
                                                                {Array.isArray(item.respective_feedback_summary) ? (
                                                                    item.respective_feedback_summary.map((feedback, index) => (
                                                                        <p key={index} style={{ margin: "6px 0px", fontStyle: "italic" }}>
                                                                            "{feedback}"
                                                                        </p>
                                                                    ))
                                                                ) : (
                                                                    <p style={{ fontStyle: "italic", color: "#999" }}>No feedback available</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <br />
                                        <br></br>
                                        {/* <div style={{ fontWeight: "600", padding: "10px 0px" }}>User Answer: </div>
                                    {
                                        stepData
                                            .filter(item => item.step === String(selectedStep))
                                            .map((item, i) => (
                                                <div key={i} style={{}}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <div>"{item.selected_option}"</div>
                                                        <br></br>
                                                    </div>
                                                </div>
                                            ))
                                    } */}
                                    </div>}
                                    {isEmpty && selectedStep === idx + 1 && <div style={{ textAlign: "left" }}>{isEmpty}</div>}

                                </div>
                            }
                            )}
                        </div>
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </>
            }


            {
                selectedTab === 3 && <>

                    <br></br>

                    <h2 style={{ padding: "1rem" }}>Form Data</h2>
                    <br></br>

                    <div>
                        <label style={{ fontSize: "1.2rem", fontWeight: "700", display: "inline-block", margin: "1rem" }}>Filters: </label>
                        {columns1.map(column => (
                            <label key={column.name} style={{ display: "inline-block", margin: "1rem" }}>
                                <input
                                    type="checkbox"
                                    checked={!column.omit}
                                    onChange={() => handleToggleColumn(column.name)}
                                />
                                {column.name}
                            </label>
                        ))}
                    </div>
                    <br></br>
                    <DataTable
                        title={"Form Data"}
                        columns={columns1.filter(col => !col.omit)}
                        data={transformedData}
                        pagination
                        paginationPerPage="50"
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        fixedHeader
                        persistTableHead
                        highlightOnHover
                        actions={<>
                            {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, transformedData)}>Export in Google Sheet</button>}
                            <button className="btn-primary" onClick={() => downloadCSV(transformedData)}>Export Csv</button>
                            <button className="btn-primary" onClick={() => downloadXLSX(transformedData)}>Export Xlsx</button>
                        </>}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </>
            }

            {
                selectedTab === 5 &&
                <>
                    <DuonutAnalytics />
                </>
            }

        </div >
    );
}

export default DuonutReports;