import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";
import { getFileSizeFromBase64, toDataUrl } from "../../../utils";
import { domain } from "../../../services/constants";
import axios from "axios";
import Resizer from 'react-image-file-resizer';
import { useLocation } from "react-router-dom";
import ImageModal from "./imageModal";

export default function SingleMultiImageOption({ item, idx, description, description2 }) {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [option, setOption] = useState([]);
    const [descriptionText, setdescriptionText] = useState([]);
    const [descriptionText2, setdescriptionText2] = useState([]);
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const location = useLocation();
    const [paramsid, setparamsid] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);


    useEffect(() => {
        setOption(item);
        setdescriptionText(description);
        setdescriptionText2(description2);
    }, [item, description, description2]);

    const handleOptionTextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;
        setOption(newText)

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of the options array and update the text of the option at index 'indx'
            const updatedOptions = elementAttrib.options.map((option, index) => {
                if (index === indx) {
                    return newText;
                }
                return option;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };

            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleDescriptionTextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;
        setdescriptionText(newText)

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of the options array and update the text of the option at index 'indx'
            const updatedDescription = elementAttrib.description.map((desc, index) => {
                if (index === indx) {
                    return newText;
                }
                return desc;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    description: updatedDescription,
                },
            };

            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleDescription2TextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;
        setdescriptionText2(newText)

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of the options array and update the text of the option at index 'indx'
            const updatedDescription2 = elementAttrib.description2.map((desc2, index) => {
                if (index === indx) {
                    return newText;
                }
                return desc2;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    description2: updatedDescription2,
                },
            };

            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const removeOption = (indx) => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of options array and remove the option at index 'indx'
            const updatedOptions = elementAttrib.options.filter((option, index) => index !== indx);
            const updatedOptionsImage = elementAttrib.optionsImage.filter((option, index) => index !== indx);
            const updatedDescription = elementAttrib.description.filter((option, index) => index !== indx);
            const updatedDescription2 = elementAttrib.description2.filter((option, index) => index !== indx);

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                    optionsImage: updatedOptionsImage,
                    description: updatedDescription,
                    description2: updatedDescription2,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleClick = () => {
        inputRef.current.value = null; // reset the value of the file input
        inputRef.current.click();
    };


    const updateActivityImage = async (e) => {
        const imageFile = e.target.files[0];

        let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
        const elementAttrib = tempElement?.elementAttributes;

        if (imageFile.type === "image/png") {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'PNG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        const updatedOptions = elementAttrib.optionsImage.map((option, index) => {
                            if (index === idx) {
                                return res.data.file_url;
                            }
                            return option;
                        });

                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                optionsImage: updatedOptions,
                            },
                        };
                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: selectedUserActivity,
                                selectedElementIdx: selectedComponent.target_id,
                                element: tempElement,
                            })
                        );
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );

        } else if (imageFile.type === "image/gif") {
            toDataUrl(e.target.files[0], (res) => {
                let result = res;

                const image_data = {
                    duonut_id: paramsid,
                    name: e.target.files[0].name,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    const updatedOptions = elementAttrib.optionsImage.map((option, index) => {
                        if (index === idx) {
                            return res.data.file_url;
                        }
                        return option;
                    });

                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...elementAttrib,
                            optionsImage: updatedOptions,
                        },
                    };
                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: selectedUserActivity,
                            selectedElementIdx: selectedComponent.target_id,
                            element: tempElement,
                        })
                    );
                }).catch((e) => {
                    console.log(e.message, e);
                });

            });
        } else {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'JPEG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        const updatedOptions = elementAttrib.optionsImage.map((option, index) => {
                            if (index === idx) {
                                return res.data.file_url;
                            }
                            return option;
                        });

                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                optionsImage: updatedOptions,
                            },
                        };
                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: selectedUserActivity,
                                selectedElementIdx: selectedComponent.target_id,
                                element: tempElement,
                            })
                        );
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );
        }


    }

    const handleClick1 = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (<>
        {isModalOpen && <ImageModal handleClose={handleCloseModal} idx={idx} />}

        <div style={{
            display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px"
        }} key={idx}  >
            <div style={{ marginRight: "1rem" }}>Option{idx + 1}</div>

            <div>
                <EditText
                    id={`option-input-${idx}`}
                    value={option}
                    style={{
                        border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                        display: "flex", justifyContent: "flex-start", alignItems: "center",
                        width: "10rem", height: "1.8rem", padding: "5px"
                    }}
                    onEditMode={() => { setTimeout(() => document.querySelector(`#option-input-${idx}`).select(), 100); }}
                    onChange={(e) => (e.target.value.length < 0 ? null : handleOptionTextChange(e, idx))}
                />
                <EditText
                    id={`description-input-${idx}`}
                    value={descriptionText}
                    style={{
                        border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                        display: "flex", justifyContent: "flex-start", alignItems: "center",
                        width: "10rem", height: "1.8rem", padding: "5px"
                    }}
                    onEditMode={() => { setTimeout(() => document.querySelector(`#description-input-${idx}`).select(), 100); }}
                    onChange={(e) => (e.target.value.length < 0 ? null : handleDescriptionTextChange(e, idx))}
                />
                <EditText
                    id={`description2-input-${idx}`}
                    value={descriptionText2}
                    style={{
                        border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                        display: "flex", justifyContent: "flex-start", alignItems: "center",
                        width: "10rem", height: "1.8rem", padding: "5px"
                    }}
                    onEditMode={() => { setTimeout(() => document.querySelector(`#description2-input-${idx}`).select(), 100); }}
                    onChange={(e) => (e.target.value.length < 0 ? null : handleDescription2TextChange(e, idx))}
                />
            </div>
            <img src="/assets/icons/upload-image.svg" onClick={handleClick} style={{ marginLeft: "0.5rem", cursor: "pointer" }} />
            <input type="file" ref={inputRef}
                onChange={(e) => updateActivityImage(e)}
                accept={"image/*"}
                onClick={(e) => { e.target.value = null }} name="postImages" style={{ display: "none" }} />

            <i className="fa fa-picture-o" aria-hidden="true" onClick={handleClick1}
                style={{ marginLeft: "0.6rem", fontSize: "1rem", opacity: "0.8", cursor: "pointer" }}></i>

            <i className="fa fa-trash-o" aria-hidden="true"
                style={{ marginLeft: "0.8rem", fontSize: "1rem", color: "#DB615C", cursor: "pointer" }} onClick={() => removeOption(idx)}></i>
        </div>
    </>
    )
}

