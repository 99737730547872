import React, { useState, useEffect, useRef } from 'react'
import './profile.css';
import { frontendUrl, backendUrl } from "../../services/constants";
import { useSelector, useDispatch } from "react-redux";
import { editUserNameAsync, editProfileImageAsync, editUserDomainAsync } from "../../redux/slices/ProfileReducer";
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from "../../services/auth";
// import PasswordInput from "../../components/Input/passwordInput";
import { requestPasswordResetEmail } from "../../services/auth";
import { toggleEnterCodeModal, toggleforgotPassword, updateEnterCodeEmail, updateResetPasswordEmail } from "../../redux/slices/formDetails";
import EditDuonutIcon from "../../assets/icons/edit-duonut.svg";
import { updateDeleteUserConfirmModal } from "../../redux/slices/formDetails";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";

import { updateUserCredentials } from "../../services/auth";

export default function Profile() {
    const userName = localStorage.getItem("userName");
    const userId = localStorage.getItem("userId");
    const userEmail = localStorage.getItem("userEmail");
    const userPic = localStorage.getItem("userProfilePicture");
    const subDomain = localStorage.getItem("subDomain");
    // const total_credit = localStorage.getItem("total_credit");
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const edate = localStorage.getItem("expireDate");
    const expireDate = new Date(edate);
    var lifetime = false;

    // Get today's date
    const today = new Date();
    // Calculate the date 5 years from today
    const fiveYearsFromToday = new Date(today.getFullYear() + 2, today.getMonth(), today.getDate());

    // Compare edate with fiveYearsFromToday
    if (expireDate > fiveYearsFromToday) {
        lifetime = true;
        // console.log("edate is more than 5 years from today.");
    } else {
        lifetime = false;
        // console.log("edate is not more than 5 years from today.");
    }

    var monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [profilePic, setprofilePic] = useState("");
    const [sub_domain, setsub_domain] = useState("");
    const [changeName, setchangeName] = useState(false);
    const [changeSubDomain, setchangeSubDomain] = useState(false);
    const [total_credit, setTotalCredit] = useState("");

    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const changepassref = useRef(null);
    const changepassref1 = useRef(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [hover, setHover] = useState(false);

    const [passwordError, setPasswordError] = useState("");
    const [message, setmessage] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    useEffect(() => {
        // console.log("userEmail", userEmail)
        if (userName !== "null") {
            setname(userName);
        }
        if (userEmail) {
            setemail(userEmail);
        }
        if (userPic !== "null") {
            setprofilePic(userPic);
        }
        if (subDomain) {
            setsub_domain(subDomain);
        }
        if (total_credit) {
            setTotalCredit(total_credit);
        }
    }, [userName, userEmail, userPic, subDomain, total_credit]);

    const onClickChangeImage = () => {
        inputRef.current.value = null; // reset the value of the file input
        inputRef.current.click();
    }

    const updateProfileImage = (e) => {
        // console.log(e)
        const formData = new FormData();
        formData.append('id', userId);
        formData.append('profile_picture', e.target.files[0], e.target.files[0].name);

        dispatch(editProfileImageAsync(formData));
        setTimeout(() => {
            getUserDetails((err, userDetails) => {
                if (err) return;
                localStorage.setItem("userName", userDetails.data.name);
                localStorage.setItem("userEmail", userDetails.data.email);
                localStorage.setItem("userId", userDetails.data.id);
                localStorage.setItem("appId", userDetails.data.app_id);
                localStorage.setItem("subDomain", userDetails.data.sub_domain);
                localStorage.setItem("proUser", userDetails.data.pro_user);
                localStorage.setItem("userProfilePicture", userDetails.data.profile_picture);
                localStorage.setItem("userType", userDetails.data.user_type);
                localStorage.setItem("expireDate", userDetails.data.expire_date);

                setprofilePic(userDetails.data.profile_picture);
            });
        }, 500); //miliseconds
    }

    const onClickChangeName = () => {
        if (!changeName) {
            setchangeName(!changeName);
        } else {
            const formData = new FormData();
            formData.append('id', userId);
            formData.append('name', name);
            dispatch(editUserNameAsync(formData));
            setchangeName(!changeName);
            localStorage.setItem("userName", name);
        }
    }

    const onClickChangeSubDomain = () => {
        if (!userProAccess) {
            dispatch(toggleProMessageModalDuonut(true));
            dispatch(updateProMessageModalMessage('Custom domain access'));
            return null;
        }

        if (!changeSubDomain) {
            setchangeSubDomain(!changeSubDomain);
        } else {
            const formData = new FormData();
            formData.append('id', userId);
            formData.append('sub_domain', sub_domain);
            dispatch(editUserDomainAsync(formData)).then((response) => {
                console.log(response);
                setchangeSubDomain(!changeSubDomain);
                localStorage.setItem("subDomain", sub_domain);
            }).catch(error => {
                console.log(error);
                setmessage("Subdomain is already exists");
                setTimeout(() => {
                    setmessage("");
                }, 3000); //miliseconds
            });

        }
    }

    const onClickChangePassword = () => {
        // changepassref.current.click();/
        requestPasswordResetEmail({ email }, (err, res) => {
            //if == message, then registered
            // if (err) return setErrorMsg("Email is not registered!");

            handleClose();
            dispatch(toggleEnterCodeModal({ fromRegister: false }));
            dispatch(updateEnterCodeEmail(userEmail));
            dispatch(updateResetPasswordEmail(userEmail));
        });
    };

    const handleClose = () => {
        // changepassref1.current.click();
    };

    const handleClick = () => {
        setPasswordError("");
        setConfirmPasswordError("");

        if (password == "" || confirmPassword === "") {
            password === "" && setPasswordError("This field cant be empty");
            confirmPassword === "" && setConfirmPasswordError("This field cant be empty");
            return;
        }

        if (password !== confirmPassword) return setConfirmPasswordError("Password don’t match. Please re-enter the passwords. ");

        updateUserCredentials({ email: userEmail, password }, (err, res) => {
            if (err) {
                if (Object.keys(err.response.data)[0] === "detail") {
                    if (err.response.data.detail === "Please enter valid password!") return setPasswordError('New password cannot be same as old password')
                } else {
                    if (err.response.data.password.length >= 1) {
                        if (err.response.data.password.includes('This password is too short. It must contain at least 8 characters.')) return setPasswordError('Password must be 8 characters long.')
                        if (err.response.data.password.includes('This password is too common.')) return setPasswordError('This password is too common.')
                        if (err.response.data.password.includes('This password is entirely numeric.')) return setPasswordError('This password is entirely numeric.')
                    }
                    return setPasswordError("Password must be 8 characters long.");
                    // setPasswordError("Password must be 8 characters long.");
                }
                return;
            }
            // alert("Password reset successfully!");
            setmessage("Password reset successfully!")
            setTimeout(() => {
                setmessage("")
                handleClose();
            }, 1000); //miliseconds
        });
    };

    const onClickDelete = () => {
        dispatch(
            updateDeleteUserConfirmModal({
                active: true,
                userId,
            })
        );
    };

    return (
        <>
            {/* <button ref={changepassref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changepassModal">
            </button>
            <div className="modal fade" id="changepassModal" tabIndex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain">
                        <div className="modal-body">
                            <button ref={changepassref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div>
                                <div className="modal_header">
                                    Enter a new password
                                </div>

                                <PasswordInput placeholder="New password" value={password} setValue={setPassword} errorMsg={passwordError} />
                                <PasswordInput placeholder="Confirm password" value={confirmPassword} setValue={setConfirmPassword} errorMsg={confirmPasswordError} />

                                <button className="btn-secondary modal_submit_btn" onClick={handleClick}>
                                    Continue
                                </button>
                                {message && <div style={{ marginTop: "5px", textAlign: "center" }}>{message}</div>}
                            </div>
                            <div style={{ position: "absolute", right: "1rem", top: "1rem" }} onClick={handleClose}>
                                <img src="/assets/icons/close.svg" />
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

            <div className='page-container' style={{ backgroundColor: "#F4F9FC" }}>
                <div className='ADP1backBtn' onClick={() => { navigate(-1) }}>
                    <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back
                </div>

                <div className='row profile_wrapper'>
                    <div className="col-xs-12 col-md-12 col-lg-4">
                        <h2 className='profile_wrapper_heading'>My Account</h2>
                        <div className='profile_container_left_field'>
                            <div className='center-y' style={{ position: "relative" }}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                onClick={() => onClickChangeImage()}>
                                {profilePic ? <img src={`${backendUrl}${profilePic}`} loading="lazy" /> : <img src='/assets/profile3.png' loading="lazy" />}
                                {hover && <img src={EditDuonutIcon} style={{ position: "absolute", width: "25px", right: "0.5rem", top: "0.5rem" }} loading="lazy" />}
                                <input type="file" ref={inputRef}
                                    onChange={(e) => updateProfileImage(e)}
                                    accept="image/*"
                                    onClick={(e) => { e.target.value = null }} name="postImages"
                                    style={{ display: "none" }} />
                            </div>
                            <div style={{ fontSize: "1.2rem", fontWeight: "700", paddingTop: "2rem" }}>
                                {userAccountType === "pro" ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                    <span >
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: "4px 5px 4px 5px", border: "1px solid gray", borderRadius: "5px", marginRight: "10px", verticalAlign: "middle" }} width="20" height="20" viewBox="0 0 16 16">
                                            <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                        </svg>
                                        Duonut Pro
                                    </span>
                                    {lifetime ? <div style={{ fontSize: "1rem", fontWeight: "400", paddingTop: "0.6rem" }}>Lifetime</div>
                                        : <div style={{ fontSize: "0.8rem", fontWeight: "400", paddingTop: "0.6rem" }}>
                                            Expires on {expireDate.getDate()} {monthNames[expireDate.getMonth()]} {expireDate.getFullYear()}
                                        </div>}
                                </div> : "Free Plan"}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-8" style={{ paddingLeft: "1.5rem" }}>
                        <div className='profile_container__field_container' style={{ marginTop: "2.4rem" }}>
                            <p>Name</p>
                            <div className='profile_container__field '>
                                <input className={changeName ? 'field_value profile_name_input' : 'field_value'}
                                    style={{ background: "transparent", color: "black" }}
                                    disabled={changeName ? false : true} value={name === null ? "" : name}
                                    onChange={(e) => (e.target.value.length > 50 ? null : setname(e.target.value))} />

                                <button className='profile_edit_btn' onClick={() => onClickChangeName()}>
                                    {changeName ? "Update" : "Edit"}
                                </button>
                            </div>
                        </div>

                        <div className='profile_container__field_container'>
                            <p>Email Address</p>
                            <div className='profile_container__field '>
                                <div className='field_value'> {email} </div>
                            </div>
                        </div>

                        <div className='profile_container__field_container'>
                            <p>Password</p>
                            <div className='profile_container__field '>
                                <div className='field_value'> Send verification Code to change password. </div>
                                <div className='profile_change_password' onClick={() => onClickChangePassword()}>
                                    Change Password
                                </div>
                            </div>
                        </div>

                        <div className='profile_container__field_container'>
                            <p>Sub Domain
                                {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                        <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                    </svg>
                                </span> */}
                            </p>
                            <div className='profile_container__field '>
                                <input className={changeSubDomain ? 'field_value profile_name_input' : 'field_value'}
                                    style={{ background: "transparent", color: "black" }}
                                    disabled={changeSubDomain ? false : true} value={sub_domain === null ? "" : sub_domain}
                                    onChange={(e) => (e.target.value.length > 100 ? null : setsub_domain(e.target.value))} />

                                {/* <button className='profile_edit_btn' onClick={() => onClickChangeSubDomain()}>
                                    {changeSubDomain ? "Update" : "Edit"}
                                </button> */}
                            </div>
                        </div>
                        {message && <div style={{ marginBottom: "1vh", color: "red" }}>{message}</div>}


                        <div className='profile_container__field_container' style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                            <p>Danger zone</p>
                            <div className='profile_container__field '>
                                <div className='field_value'> This will permanently delete your entire account. <br></br>All your duonuts and workspaces will be deleted. </div>
                                <button className='profile_delete_account_btn' onClick={() => onClickDelete()}>
                                    Delete account
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
