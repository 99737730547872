import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./CreateFromScratch.css";
import { motion, AnimatePresence } from "framer-motion";
import { toggleCreateFromScratchModalDuonut, toggleCreateFromScratchModalAgain } from "../../../redux/slices/CreateFromScratchReducer";
// import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
// import useOutsideAlerter from "../../../hooks/useOutsideAlerter"
// import useWindowDimensions from "../../../hooks/useWindowDimensions";
// import { backendUrl, frontendUrl } from "../../../services/constants";
import { toggleChangeDuonutNameModal } from "../../../redux/slices/DuonutNameReducer";

export default function CreateFromScratch() {
    const dispatch = useDispatch();
    const { modalActive, modalAgain } = useSelector((state) => state.createFromScratch);
    // const { width } = useWindowDimensions();
    // const location = useLocation();
    const navigate = useNavigate();
    // const templateref = useRef(null);
    // const templateref1 = useRef(null);
    // const templateopenref = useRef(null)
    // const [selectedItem, setselectedItem] = useState(1);
    // const [selectedId, setselectedId] = useState(0);
    // const [selectedDisplayId, setselectedDisplayId] = useState(0);
    // const [selectedName, setselectedName] = useState("");
    // const [mouseEnterId, setmouseEnterId] = useState(0);

    const handleClose = () => {
        dispatch(toggleCreateFromScratchModalDuonut(false));
        dispatch(toggleCreateFromScratchModalAgain(true));
    };

    // const onClickTemplateButton = (id, name) => {
    //     templateref1.current.click();
    //     dispatch(toggleCreateFromScratchModalDuonut(false));
    //     dispatch(updateDuonutDefaultName(name));
    //     dispatch(updateDuonutNavigateUrl(`/design?selectedDuonutId=${id}`));
    //     dispatch(toggleChangeDuonutNameModal(true));
    //     // window.open(`/design?selectedDuonutId=${id}`);
    //     //   window.open('/duonut/' + key);
    //     // navigate('/duonut/' + key);
    //     // window.location.reload();
    // }

    // const handleCloseModal = () => {
    //     templateref1.current.click();
    // }
    // useOutsideAlerter(templateopenref, handleCloseModal);

    // useEffect(() => {
    //     if (width <= 768) {
    //         setselectedItem(2);
    //     }
    // }, [width]);

    // const onClickPreview = (id, displayId, name) => {
    //     // console.log(masterData)
    //     setselectedId(id);
    //     setselectedDisplayId(displayId);
    //     setselectedName(name);
    //     setTimeout(() => {
    //         templateref.current.click();
    //     }, 500); //miliseconds
    // }

    return (
        <>
            {/* <button ref={templateref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#templateModal${selectedId}`}>
            </button>
            <div className="modal fade" ref={templateopenref} style={{ backgroundColor: "rgba(0, 15, 51, 0.5)", zIndex: "9999" }} id={`templateModal${selectedId}`} tabIndex="-1" aria-labelledby="templateModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: width >= 768 ? "65vw" : "100%", margin: "0.8rem auto 0rem auto" }}>
                    <div className="modal-content CPST1modalMain">
                        <div className="modal-body" style={{ padding: "0.7rem" }}>
                            <button ref={templateref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className="CPST1close-icon" style={{ zIndex: "2" }} onClick={() => handleCloseModal()}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <h2 style={{ paddingTop: "1rem", paddingLeft: "1rem", textAlign: "left", position: "relative", zIndex: "1" }}> {selectedName}</h2>
                            {selectedItem === 1 && <div style={{ padding: "10px 1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <iframe className='CPST1iframe' id="iframeid" src={`${frontendUrl}/duonut/${selectedDisplayId}`} title="Duonut Iframe"></iframe>
                            </div>}
                            {selectedItem === 2 && <div style={{ padding: "10px 1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className='CPST1mobileScreen'></div>
                                <iframe className='CPST1iframeMob' id="iframeid" src={`${frontendUrl}/duonut/${selectedDisplayId}`} title="Duonut Iframe"></iframe>
                            </div>}

                            <div className='CPST1modalBottom'>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {width >= 768 && <div className='CPST1icon' style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "8px 11px 13px 12px" }} onClick={() => setselectedItem(1)}>
                                        <i className="fa fa-desktop" aria-hidden="true"></i>
                                    </div>}
                                    {width >= 768 && <div className='CPST1icon' style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }} onClick={() => setselectedItem(2)}>
                                        <i className="fa fa-mobile" aria-hidden="true" style={{ fontSize: "2rem" }}></i>
                                    </div>}
                                </div>
                                <button className='CPST1TemplateButton' onClick={() => onClickTemplateButton(selectedId, selectedName)}>
                                    <i className="fa fa-plus-circle" style={{ paddingRight: "8px", fontSize: "1rem" }} aria-hidden="true"></i>
                                    USE THIS TEMPLATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <AnimatePresence>
                {modalActive && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.7, ease: "backOut" }} className="modal_container">
                        <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -30 }} transition={{ duration: 0.7, ease: "backOut" }} className="CFS1modal row" style={{ position: "relative" }}>
                            <div className="CFS1MainContainer">
                                <h1 style={{ marginTop: "2rem" }}>Create your workflow or form</h1>
                                {/* {!modalAgain && <> <div style={{ marginBottom: "2rem" }}>Create a blank form or choose from selected templates to start gathering data</div>
                                    <div className="CFS1templateContainer">
                                        <div className="CFS1template">
                                            <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleChangeDuonutNameModal(true)); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" fill="none">
                                                    <path fill="currentColor" fill-rule="evenodd" d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z" clip-rule="evenodd"></path>
                                                </svg>
                                                <div>Start From Scratch</div>

                                            </div>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); navigate('/ai') }}>
                                                <img src="/assets/generate_ai.png" style={{ height: "4.5rem" }} loading="lazy" />
                                                <div>Generate with AI</div>

                                            </div>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(1)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 1 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1643, 8236057811, "Feedback Template 1")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/1.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Feedback Template 1</h3>
                                            <button onClick={() => onClickTemplateButton(1643, "Feedback Template 1")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(2)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 2 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1801, 5673805858, "Market Research Template")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/2.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Market Research Template</h3>
                                            <button onClick={() => onClickTemplateButton(1801, "Market Research Template")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(3)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 3 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1261, 4682438228, "Utility Checklist Template")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/3.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Utility Checklist Template</h3>
                                            <button onClick={() => onClickTemplateButton(1261, "Utility Checklist Template")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                        <div className="CFS1template">
                                            <div className="CFS1templateImage"
                                                onMouseEnter={() => setmouseEnterId(4)}
                                                onMouseLeave={() => setmouseEnterId(0)}>
                                                {mouseEnterId === 4 && <div className="CFS1templatePreviewContainer" onClick={() => onClickPreview(1721, 4799020191, "Learning Template 1")}>
                                                    <span className="CFS1templatePreview"><i className="fa fa-eye" aria-hidden="true"></i> Preview</span>
                                                </div>}
                                                <img src="/assets/templates/4.png" className="" loading="lazy" />
                                            </div>
                                            <h3 className="CFS1templateHeading">Learning Template 1</h3>
                                            <button onClick={() => onClickTemplateButton(1721, "Learning Template 1")} className="btn-secondary" style={{ width: "100%", padding: "0.8rem" }} >Use Template</button>
                                        </div>
                                    </div>
                                    <button className="btn-primary" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); navigate('/templates/all'); }} style={{ width: "auto", padding: "0.8rem", margin: "2rem auto" }} >See More Templates</button>
                                </>} */}


                                {modalAgain && <div className="CFS1templateContainer" style={{ marginTop: "1rem" }}>
                                    <div className="CFS1templateBlock">
                                        <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); dispatch(toggleChangeDuonutNameModal(true)); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" fill="none">
                                                <path fill="#3184C9" fill-rule="evenodd" d="M35.166 13.834a2.667 2.667 0 1 0-5.333 0v16h-16a2.667 2.667 0 1 0 0 5.333h16v16a2.667 2.667 0 1 0 5.334 0v-16h16a2.667 2.667 0 1 0 0-5.333h-16v-16Z" clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>Start From Scratch</div>

                                    </div>
                                    <div className="CFS1templateBlock">
                                        <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); navigate('/') }}>
                                            <img src="/assets/generate_ai1.png" alt="duonut" loading="lazy" />
                                        </div>
                                        {/* <div style={{ marginTop: "1rem" }}>Generate with AI</div> */}
                                        <div style={{ marginTop: "1rem" }}>Create from Template</div>

                                    </div>
                                    {/* <div className="CFS1templateBlock">
                                        <div className="CFS1startscratch" onClick={() => { dispatch(toggleCreateFromScratchModalDuonut(false)); window.location.href = 'https://duonut.com/templates'; }}>

                                            <img src="/assets/template_icon.png" alt="duonut" loading="lazy" />
                                        </div>
                                        <div style={{ marginTop: "1rem" }}>Use Template</div>
                                    </div> */}
                                </div>}

                            </div>

                            <div className="close-icon icon-button CFS1close" onClick={handleClose}>
                                <img src="/assets/icons/close.svg" />
                            </div>
                        </motion.div>
                        <div className="modal-overlay"></div>
                    </motion.div>
                )}
            </AnimatePresence >
        </>
    );
}
