import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./UserSetting.css";
import { motion, AnimatePresence } from "framer-motion";
import { toggleUserSettingModalDuonut, saveUserSettingAsync, showUserSettingresponse, saveDuonutMappingAsync, editDuonutMappingAsync } from "../../../redux/slices/UserSettingReducer";
import { getMyDuonuts } from '../../../services/duonut'
import { frontendUrl, backendUrl } from "../../../services/constants";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import * as FileSaver from 'file-saver';

export default function UserSetting() {
    const appId = localStorage.getItem("appId");
    const dispatch = useDispatch();
    const modalActive = useSelector((state) => state.userSetting.modalActive);
    const [duonutMappingData, setduonutMappingData] = useState([]);
    const [duonuts, setDuonuts] = useState([]);
    const [selectedDuonut, setselectedDuonut] = useState("");
    const [selectedDuonutId, setselectedDuonutId] = useState(0);
    const [selectedMappingType, setselectedMappingType] = useState("");
    const [isedit, setisedit] = useState(false);
    const [editId, seteditId] = useState(0);
    const [message, setmessage] = useState("");
    const [ischange, setischange] = useState(false);
    const [removeDuonutData, setremoveDuonutData] = useState("");
    const [qrDuonutData, setqrDuonutData] = useState("");
    const [errorMessage, seterrorMessage] = useState("");

    const MappingType = [
        "alpha",
        "beta",
        "gamma",
        "delta",
        "epsilon",
        "zeta",
        "eta",
        "theta",
        "lota",
        "kappa"
    ];

    // console.log(MappingType);

    const ref = useRef(null);
    const ref1 = useRef(null);
    const removeRef = useRef(null);
    const removeRef1 = useRef(null);
    const mappingqrref = useRef(null);
    const mappingqrref1 = useRef(null);
    // to close duonut options menu
    const modalRef = useRef(null)
    const removemodalRef = useRef(null)
    const handleCloseModal = () => { setisedit(false); seteditId(0); }
    useOutsideAlerter(modalRef, handleCloseModal);

    const handleremoveModal = () => { setremoveDuonutData(""); }
    useOutsideAlerter(removemodalRef, handleremoveModal);

    const handleDuonutChange = event => {
        setselectedDuonut(event.target.value);
        // console.log(event.target.value);
        duonuts?.map((item, indx) => {
            if (item.name === event.target.value) {
                setselectedDuonutId(item.id);
            }
            return null;
        })
    };

    const handleClose = () => {
        dispatch(toggleUserSettingModalDuonut(false));
    };

    useEffect(() => {
        dispatch(saveUserSettingAsync());
        // console.log("inside");
    }, [ischange]);

    const mappingData = useSelector(showUserSettingresponse);
    const mappingData1 = mappingData[0];
    // console.log(mappingData1);

    useEffect(() => {
        if (mappingData1) {
            var data = mappingData1.duonut_mapping_data
            // console.log(data);
            setduonutMappingData(data);
        }
    }, [mappingData1])

    useEffect(() => {
        getMyDuonuts((err, res) => {
            if (err) console.log(err.response)
            else {
                if (res.data.length === 0) return
                setDuonuts(res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))
                // console.log(res)
            }
        })
    }, [])

    const handelClickNew = () => {
        setselectedMappingType(MappingType[duonutMappingData.length])
        ref.current.click();
    }

    const submit = () => {
        if (isedit) {
            var editMappingData = {
                id: editId,
                type_id: selectedMappingType,
                duonut_id: selectedDuonutId,
                enable: true
            }
            dispatch(editDuonutMappingAsync(editMappingData));
            setisedit(false);
            setmessage("Duonut page Mapping edited successfully");
        } else {
            var mappingData = {
                type_id: selectedMappingType,
                duonut_id: selectedDuonutId,
                enable: true
            }
            dispatch(saveDuonutMappingAsync(mappingData));
            setmessage("Duonut page Mapping created successfully.");
        }

        setTimeout(() => {
            setmessage("");
            setischange(!ischange);
            ref1.current.click();
            setselectedDuonutId(0);
            setselectedDuonut("");
            setselectedMappingType("");
        }, 3000); //miliseconds

    }

    const handelClickEdit = (item) => {
        setisedit(true);
        seteditId(item.id);
        setselectedMappingType(item.type_id);
        ref.current.click();

    }

    const handelClickCopy = (item) => {
        if (item.enable) {
            var copyText = `${frontendUrl}/pagemapping/${item.type_id}?app_id=${appId} `;
            // copyText.select();
            // copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText);
        } else {
            seterrorMessage("Duonut is not Mapped.");
            setTimeout(() => {
                seterrorMessage("");
            }, 3000); //miliseconds
        }
    }

    const handelClickView = (item) => {
        if (item.enable) {
            window.open('/pagemapping/' + item.type_id + '?app_id=' + appId);
        } else {
            seterrorMessage("Duonut is not Mapped.");
            setTimeout(() => {
                seterrorMessage("");
            }, 3000); //miliseconds
        }
    }

    const handelClickQrcode = (item) => {
        if (item.enable) {
            setqrDuonutData(item);
            mappingqrref.current.click();
        } else {
            seterrorMessage("Duonut is not Mapped.");
            setTimeout(() => {
                seterrorMessage("");
            }, 3000); //miliseconds
        }
    }

    const handleokbtn = () => {
        var editMappingData = {
            id: removeDuonutData.id,
            type_id: removeDuonutData.type_id,
            duonut_id: removeDuonutData.duonut,
            enable: false
        }
        dispatch(editDuonutMappingAsync(editMappingData));
        setTimeout(() => {
            setischange(!ischange);
            setremoveDuonutData("");
            removeRef1.current.click();
        }, 500); //miliseconds
    }
    const handlecancelbtn = () => {
        setremoveDuonutData("");
        removeRef1.current.click();

    }

    const handelClickDelete = (item) => {
        if (item.enable) {
            setremoveDuonutData(item);
            removeRef.current.click();
        } else {
            seterrorMessage("Duonut mapping is already removed.");
            setTimeout(() => {
                seterrorMessage("");
            }, 3000); //miliseconds
        }
    }

    const continuebtn = () => {
        mappingqrref1.current.click();
    }
    const downloadbtn = () => {
        let qrurl = backendUrl + qrDuonutData.qrcode;
        // console.log(qrurl);
        let url;
        if (qrurl?.toString().includes("https")) {
            url = qrurl;
        } else {
            url = qrurl.toString().replace("http", "https");
        }

        fetch(url)
            .then(res => res.blob())
            .then(blob => {
                FileSaver.saveAs(blob, `mappingqrcode-${qrDuonutData.id}.png`);
            })
            .catch(error => console.log(error));
    }


    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#userSettingModal">
        </button>
            <div className="modal fade" ref={modalRef} style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="userSettingModal" tabIndex="-1" aria-labelledby="userSettingModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content userSettingmodalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='userSettingModalTitle'> Create a new Duonut Mapping</div>
                            <div>Duonut mapping can be done if you want to keep the same QR code or link, but wants to change the activity from backend. </div>
                            {/* <br></br> */}
                            <div className='usersettingInputbox'>
                                <div style={{ textAlign: "left", padding: "4px 0px" }}>Type of Mapping</div>
                                <input value={selectedMappingType} disabled />
                            </div>
                            <div className='usersettingDropdown'>
                                <div style={{ textAlign: "left", padding: "4px 0px" }}>Select the Duonut Name</div>
                                <select id="mySelect" value={selectedDuonut} onChange={e => handleDuonutChange(e)}>
                                    <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select the Duonut Name</option>
                                    {duonuts?.map((item, indx) => {
                                        return (
                                            <option key={indx} value={item.name}>{item.name}</option>
                                        )
                                    }
                                    )}
                                </select>
                            </div>
                            {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                            <button className="usersettingModalSubmitbtn" disabled={message ? true : false} style={{ margin: "2vh", backgroundColor: message ? "rgba(7, 168, 7, 0.897)" : "" }} onClick={() => submit()}>{message ? "Submitted" : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <button ref={removeRef} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#userSettingRemoveModal">
            </button>
            <div className="modal fade" ref={removemodalRef} style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="userSettingRemoveModal" tabIndex="-1" aria-labelledby="userSettingRemoveModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content userSettingmodalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={removeRef1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='userSettingModalTitle'> Are you sure want to remove this Mapping Duonut?</div>
                            <br></br>

                            {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                            <button className="usersettingModalSubmitbtn" style={{ margin: "2vh" }} onClick={() => handleokbtn()}>OK</button>
                            <button className="usersettingModalSubmitbtn" style={{ margin: "2vh" }} onClick={() => handlecancelbtn()}>cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <button ref={mappingqrref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mappingqrcodeModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="mappingqrcodeModal" tabIndex="-1" aria-labelledby="mappingqrcodeModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content QRC1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={mappingqrref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div className='PM1shareTitle'> Scan QR Code and view this Duonut</div>
                            {/* <br></br> */}
                            <img className='qrcodeImage' src={`${backendUrl}${qrDuonutData.qrcode}`} alt="" />
                            <button className='QRC1Continuebtn' onClick={() => downloadbtn()}>Download</button>
                            <button className='QRC1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {modalActive && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.7, ease: "backOut" }} className="modal_container">
                        <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -30 }} transition={{ duration: 0.7, ease: "backOut" }} className="form_modal row">
                            <div className="usersettingContainer">


                                <h1 style={{ textAlign: "center" }}>Duonut Mapping Page</h1>
                                <div className="usersettingDetails">
                                    <div>
                                        <div>App Id - {appId}</div>
                                        <div>Email - {localStorage.getItem("userEmail")}</div>
                                    </div>
                                    <button className="usersettingNewbtn" style={{ margin: "2vh" }} onClick={() => handelClickNew()}>+ NEW</button>
                                </div>
                                <br></br>
                                <div>Start mapping your Duonut with embed code or QR code here</div>
                                {errorMessage && <div style={{ color: "red", textAlign: "center" }}>{errorMessage}</div>}
                                <div className="userSettingTable" key={duonutMappingData}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Type</th>
                                                <th>Duonut Name</th>
                                                <th>Action</th>
                                            </tr>
                                            {duonutMappingData?.map((val, key) => {
                                                return (
                                                    <tr key={key} style={key % 2 === 0 ? { backgroundColor: "#F4F9FC", color: "black" } : { backgroundColor: "white", color: "black" }}>
                                                        <td>{val.type_id}</td>
                                                        <td>{duonuts?.map((item, indx) => {
                                                            if (item.id !== val.duonut)
                                                                return null;
                                                            return (<span key={indx}>{val.enable ? item.name : "-"}</span>);
                                                        })}</td>
                                                        <td className="userSettingTableAction">
                                                            <div className="userSettingTableActionBtn" data-tip="Edit" onClick={() => handelClickEdit(val)}><i className="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                                                            <div className="userSettingTableActionBtn" data-tip="Copy" onClick={() => handelClickCopy(val)}><i className="fa fa-files-o" aria-hidden="true"></i></div>
                                                            <div className="userSettingTableActionBtn" data-tip="View" onClick={() => handelClickView(val)}><i className="fa fa-eye" aria-hidden="true"></i></div>
                                                            <div className="userSettingTableActionBtn" data-tip="QR code" onClick={() => handelClickQrcode(val)}><i className="fa fa-qrcode" aria-hidden="true"></i></div>
                                                            <div className="userSettingTableActionBtn" data-tip="Remove" onClick={() => handelClickDelete(val)}><i className="fa fa-trash" aria-hidden="true"></i></div>
                                                            <ReactTooltip place="top" effect="solid" />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                                <div className="close-icon icon-button" onClick={handleClose}>
                                    <img src="/assets/icons/close.svg" />
                                </div>
                            </div>

                        </motion.div>
                        {/* <div className="modal-overlay" onClick={handleClose}></div> */}
                    </motion.div>
                )}
            </AnimatePresence></>
    );
}
