import React, { useRef, useEffect, useState } from 'react';
import { frontendUrl } from "../../../services/constants";
import './ShareModal.css';
import "../../../assets/bootstrap.min.css";
// import "../../../assets/bootstrap.bundle.min.js";
import { useNavigate } from 'react-router-dom';
import ProlificPanel from '../../../Pages/AiDataNew/Panel/ProlificPanel';
import RespondentPanel from '../../../Pages/AiDataNew/Panel/RespondentPanel';

const ShareModal = (props) => {
    // console.log(props);
    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }
    const duonutName = props.shareData.duonut_name;
    const displayId = props.shareData.display_id;
    const password = props.shareData.password;
    const message = props.shareData.message;
    const ref = useRef(null);
    const ref1 = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const [iscopylink, setiscopylink] = useState(false);
    const [show, setShow] = useState(true);
    const [socials, setShowSocials] = useState(false);
    const [link, setShowLink] = useState(false);
    const navigate = useNavigate();
    const [selecteShare, setselecteShare] = useState(show);
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const [selectedPanelTab, setselectedPanelTab] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            ref1.current.click();
        }, 300000); //miliseconds
    }, []);


    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }
    const viewbtn = () => {
        ref1.current.click();
        window.open(`/duonut/${displayId}`);
        // window.location.reload();
    }

    const onclickembedcode = () => {
        setShow(true);
        setShowSocials(false);
        setiscopylink(false);
        setShowLink(false);
        setiscopylink(false);
        setiscopy(false);
        setIsPanelVisible(false);
    }
    const onclicksocials = () => {
        setShowSocials(true);
        setShow(false);
        setiscopylink(false);
        setShowLink(false);
        setiscopylink(false);
        setiscopy(false);
        setIsPanelVisible(false);
    }
    const onclickcopylink = () => {
        setShowLink(true);
        setShow(false);
        setShowSocials(false);
        setiscopylink(false);
        setiscopy(false);
        setIsPanelVisible(false);

    }
    const onclickpanel = () => {
        setIsPanelVisible(true);
        setShowLink(false);
        setShow(false);
        setShowSocials(false);
        setiscopylink(false);
        setiscopy(false);
    }
    const copylink = () => {
        var copyText = `${frontend_url}/duonut/${displayId}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink(true);
    }
    const copycode = () => {
        var copyText = document.getElementById("iframearea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }
    var shareLink;
    if (password) {
        shareLink = `I liked ${duonutName} duonut and I think you will like it too.
        %0aSee here - ${frontend_url}/duonut/${displayId}  
        %0aPassword - ${password}
        %0a\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538} 
        %0a%23itsduonut`;
    } else {
        shareLink = `${frontend_url}/duonut/${displayId}`;
    }

    const iframe = `<iframe src="${frontend_url}/duonut/${displayId} " style="width: 100%; min-height: 80vh;" title="Duonut Iframe"></iframe>`;
    const copyLink = `${frontend_url}/duonut/${displayId}`;
    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#shareModal${displayId}`}>
        </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`shareModal${displayId}`} tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px", overflow: "scroll" }}>
                        <div className="modal-body" style={{ maxHeight: "90vh" }}>
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className="close-icon icon-button publishCloseIcon" onClick={() => ref1.current.click()}>
                                <img src="/assets/icons/close.svg" />
                            </div>
                            <div>{duonutName}{message}</div>

                            <div className='SM1shareTitle'> Share your Duonut</div>
                            {/* <div className='PM1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div> */}
                            <br></br>
                            <div className="">
                                <div className='' style={{ display: "flex", justifyContent: "space-around" }}>
                                    <p className='SM1modalShare-btn' onClick={() => onclicksocials()}
                                        style={socials ? { color: "white", backgroundColor: "#0E2C45" } : {}}>Social</p>
                                    <p className='SM1modalShare-btn' onClick={() => onclickembedcode()}
                                        style={show ? { color: "white", backgroundColor: "#0E2C45" } : {}}>HTML Embed</p>
                                    <p className='SM1modalShare-btn' onClick={() => onclickcopylink()}
                                        style={link ? { color: "white", backgroundColor: "#0E2C45" } : {}}>Link</p>
                                    <p className='SM1modalShare-btn' onClick={() => onclickpanel()}
                                        style={isPanelVisible ? { color: "white", backgroundColor: "#0E2C45" } : {}}>Panel</p>
                                </div>
                                <hr />
                                {socials && <div className='SM1modalShare-icons'>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${frontend_url}/duonut/${displayId} `)}>
                                        <i className="fa fa-facebook" aria-hidden="false"></i>
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${frontend_url}/duonut/${displayId} ?page=ld`)}>
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} >
                                        <img style={{ marginTop: "6px" }} src="/assets/icons/twitter2.svg" />
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)}>
                                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://classroom.google.com/share?url=${frontend_url}/duonut/${displayId}`)}
                                        style={{ display: "block", cursor: "pointer", }}>
                                        <img src='/assets/classroom.png' />
                                    </label>
                                </div>}
                                {/* <div className='link-share'>
                                    <label className='SM1iconCircle' onClick={() => onclickembedcode()}>
                                        <i className="fa fa-link" aria-hidden="true"></i>
                                    </label>
                                </div> */}

                            </div>
                            <br></br>
                            {show && <>
                                <textarea id='iframearea' rows="4" key={iframe} readOnly
                                    style={{ width: "80%", padding: "15px", margin: "1vh auto", fontSize: "15px", height: "40%", display: "block", borderRadius: "5px", color: "white", background: "#0E2C45", cursor: "pointer" }}>
                                    {iframe}
                                </textarea>
                                <button className='SM1Continuebtn' onClick={() => copycode()} style={{ background: iscopy ? "#4fec4f" : "transparent", color: iscopy ? "white" : "#0E2C45" }}>
                                    {iscopy ? (
                                        <> <>
                                            <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Copied
                                        </></>
                                    ) : (
                                        <>
                                            <i className="fa fa-files-o" aria-hidden="true"></i>&nbsp; Copy
                                        </>
                                    )}</button>
                            </>
                            }

                            {link &&
                                <><textarea id='iframearea' rows="4" key={copyLink} readOnly
                                    style={{ width: "80%", padding: "15px", margin: "1vh auto", fontSize: "15px", height: "40%", display: "block", borderRadius: "5px", color: "white", background: "#0E2C45", cursor: "pointer" }}>
                                    {copyLink}
                                </textarea>
                                    <button className='SM1Continuebtn' onClick={() => copylink()} style={{ background: iscopylink ? "#4fec4f" : "transparent", color: iscopylink ? "white" : "#0E2C45" }}>
                                        {iscopylink ? (
                                            <>
                                                <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Copied
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa fa-files-o" aria-hidden="true"></i>&nbsp; Copy
                                            </>
                                        )}
                                    </button>

                                </>
                            }
                            {isPanelVisible &&
                                <div >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                        <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedPanelTab === 1 ? "700" : "400" }}
                                            onClick={() => setselectedPanelTab(1)}>
                                            {selectedPanelTab === 1 && <div className="navbar-selected-line"></div>}
                                            Prolific </div>
                                        <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedPanelTab === 2 ? "700" : "400" }}
                                            onClick={() => setselectedPanelTab(2)}>
                                            {selectedPanelTab === 2 && <div className="navbar-selected-line"></div>}
                                            Respondent </div>
                                    </div>
                                    {selectedPanelTab === 1 &&
                                        <ProlificPanel link={`${frontend_url}/duonut/${displayId}`} />}
                                    {selectedPanelTab === 2 &&
                                        <RespondentPanel link={`${frontend_url}/duonut/${displayId}`} />}
                                </div>
                            }
                            {!isPanelVisible &&
                                <div>
                                    <hr />
                                    <button className='SM1Continuebtn' onClick={() => viewbtn()}>View</button>
                                    <button className='SM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}

export default ShareModal;