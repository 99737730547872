import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isLoggedIn: false,
  isRegistered: false,
  userAccountType: "",
  userProAccess: false,
  userTrialDays: 0,
  slack_key: "",
  gmail_key: "",
  total_credit: 0,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAuth: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    updateIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    updateIsRegistered: (state, { payload }) => {
      state.isRegistered = payload;
    },
    updateUserAccountType: (state, { payload }) => {
      state.userAccountType = payload;
    },
    updateUserProAccess: (state, { payload }) => {
      state.userProAccess = payload;
    },
    updateSlackKey: (state, { payload }) => {
      state.slack_key = payload;
    },
    updateUserTrialDays: (state, { payload }) => {
      state.userTrialDays = payload;
    },
    updateGmailKey: (state, { payload }) => {
      state.gmail_key = payload;
    },
    updateTotalCredit: (state, { payload }) => {
      state.total_credit = payload;
    },
  },
});

export const { updateAuth, updateIsLoggedIn, updateIsRegistered, updateUserAccountType, updateUserProAccess,
  updateSlackKey, updateUserTrialDays, updateGmailKey, updateTotalCredit } = user.actions;
export default user.reducer;
