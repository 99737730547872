import React, { useContext, useEffect, useRef, useState } from "react";
import '../AiSettingNew/AiSettingNew.css';
import axios from "axios";
import { domain, frontendUrl } from "../../../services/constants";

import { getUserDetails } from "../../../services/auth";
import { updateTotalCredit } from "../../../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";

import Modal from '../Modal';

const ProlificPanel = ({ link }) => {
    const { isLoggedIn, userAccountType, userProAccess, total_credit } = useSelector((state) => state.user);


    const dispatch = useDispatch();

    const modelRef = useRef(null);
    const qrref = useRef(null);
    const qrref1 = useRef(null);
    const panelref = useRef(null);
    const panelref1 = useRef(null);

    const [quantity, setQuantity] = useState(10);
    const [incentive, setIncentive] = useState(10);
    const [allcountry, setallcountry] = useState([]);
    const [country, setcountry] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [allLanguages, setAllLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
    const [allGender, setAllGender] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);
    const [showGenderDropdown, setShowGenderDropdown] = useState(false);
    const [allDevices, setAllDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);
    const [showDeviceDropdown, setShowDeviceDropdown] = useState(false);
    const [allIndustry, setAllIndustry] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
    const [allIncome, setAllIncome] = useState([]);
    const [selectedIncome, setSelectedIncome] = useState([]);
    const [showIncomeDropdown, setShowIncomeDropdown] = useState(false);
    const [allCompanySize, setAllCompanySize] = useState([]);
    const [selectedCompanySize, setSelectedCompanySize] = useState([]);
    const [showCompanySizeDropdown, setShowCompanySizeDropdown] = useState(false);
    const [allEducation, setAllEducation] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [showEducationDropdown, setShowEducationDropdown] = useState(false);
    const [allSkills, setAllSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [showSkillsDropdown, setShowSkillsDropdown] = useState(false);
    const [allJob, setAllJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [showJobDropdown, setShowJobDropdown] = useState(false);
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    // const [age, setAge] = useState(18);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    // const [ageRange, setAgeRange] = useState([18, 100]); // Default age range
    // const [totalCost, setTotalCost] = useState(140);
    const [type, setType] = useState("B2C");

    const [selectedCountrtKeys, setSelectedCountrtKeys] = useState([]);
    const [selectedLanguageKeys, setSelectedLanguageKeys] = useState([]);
    const [selectedGenderKeys, setSelectedGenderKeys] = useState([]);
    const [selectedIndustryKeys, setSelectedIndustryKeys] = useState([]);
    const [selectedIncomeKeys, setSelectedIncomeKeys] = useState([]);
    const [selectedCompanySizeKeys, setSelectedCompanySizeKeys] = useState([]);
    const [selectedEducationKeys, setSelectedEducationKeys] = useState([]);
    const [selectedSkillsKeys, setSelectedSkillsKeys] = useState([]);
    const [selectedJobKeys, setSelectedJobKeys] = useState([]);


    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', message: '' });
    const [buttonText, setButtonText] = useState('Create');

    const dropdownRef = useRef(null);
    const genderDropdownRef = useRef(null);
    const languageDropdownRef = useRef(null);
    const educationDropdownRef = useRef(null);
    const skillDropdownRef = useRef(null);
    const jobDropdownRef = useRef(null);
    const incomeDropdownRef = useRef(null);
    const industryDropdownRef = useRef(null);
    const deviceDropdownRef = useRef(null);
    const companySizeDropdownRef = useRef(null);


    const handleCloseModal = () => setModalOpen(false);

    const [customFields, setCustomFields] = useState({
        location: '',
        gender: '',
        device: '',
        income: '',
        // age: '',
        education: '',
        industry: '',
        size: '',
        totalCost: '150',
    });
    // const industries = [
    //     "Technology",
    //     "Finance",
    //     "Healthcare",
    //     "Retail",
    //     "Manufacturing",
    //     "Education",
    //     "Real Estate",
    //     "Energy",
    //     "Transportation",
    //     "Entertainment",
    //     "Agriculture",
    //     "Telecommunications",

    // ];

    const calculateCost = (quantity, incentive) => {
        const recruitingCost = 5;
        return quantity * (parseFloat(incentive) + recruitingCost);
    };
    const [totalCost, setTotalCost] = useState(() =>
        calculateCost(10, 10)
    );
    useEffect(() => {
        setTotalCost(calculateCost(quantity, incentive));
    }, [quantity, incentive]);

    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail");
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);


    const formRef = useRef(null);

    const resetBtn = () => {
        formRef.current.reset();
        setCustomFields({
            gender: 'select',
            education: 'select',
            // age: 'select',
            device: 'select',
            email: '',
            phone: '',

        });
        setTitle("");
        setSelectedCountries([]);
        setQuantity(10);
        setSelectedLanguages([]);
        setSelectedGender([]);
        setSelectedDevice([]);
        setSelectedIndustry([]);
        // setAgeRange([18, 100]);
        setSelectedCompanySize([]);
        setSelectedEducation([]);
        setSelectedJob([]);
        setSelectedSkills([]);
        setSelectedIncome([]);
        setIncentive(10);
        setTitleError(false);
    };
    useEffect(() => {
        axios({
            method: "GET",
            url: `${domain}/duonut/prolific_filter`,
            headers: {
                "Content-Type": "application/json"
            },

        })
            .then(data => {
                console.log('Full API Data:', data);
                data = data.data
                if (data && (data?.prolific_response_filter?.results).length > 0) {
                    console.log('Filters Array:', data.prolific_response_filter.results);

                    // Extract Country Filter
                    const filter = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'current-country-of-residence'
                    );
                    if (filter) {
                        const countries = Object.values(filter.choices).map(value => {
                            // console.log(`Country Value: ${value}`);
                            return value;
                        });
                        // console.log('Countries:', countries);
                        setallcountry(countries);
                    } else {
                        console.warn(
                            'Country filter or choices missing. Setting empty countries list.'
                        );
                        setallcountry([]);
                    }

                    // Extract Language Filter
                    const languageFilter = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'fluent-languages'
                    );
                    if (languageFilter) {
                        const languages = Object.values(languageFilter.choices).map(value => {
                            // console.log(`Language Value: ${value}`);
                            return value;
                        });
                        // console.log('Languages:', languages);
                        setAllLanguages(languages);
                    } else {
                        console.warn(
                            'Language filter or choices missing. Setting empty languages list.'
                        );
                        setAllLanguages([]);
                    }

                    const filters = data.prolific_response_filter.results.filter(
                        f => f.filter_id === 'work-function');
                    // f.filter_id === 'industry' ||

                    // f.filter_id === 'function-in-organization'
                    // );

                    if (filters.length > 0) {
                        const combinedChoices = filters.flatMap(filter => {
                            const choices = Object.values(filter.choices);
                            // console.log(`${filter.filter_id} Choices:`, choices);
                            return choices;
                        });

                        // console.log('Combined Choices:', combinedChoices);
                        setAllIndustry(combinedChoices);
                    } else {
                        console.warn('No relevant filters or choices found. Setting an empty industry list.');
                        setAllIndustry([]);
                    }


                    // Decvices Filter
                    const deviceFilter = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'devices-with-screens'
                    );
                    if (deviceFilter) {
                        const device = Object.values(deviceFilter.choices).map(value => {
                            // console.log(`Device Value: ${value}`);
                            return value;
                        });
                        // console.log('Device:', device);
                        setAllDevices(device);
                    } else {
                        console.warn(
                            'Device filter or choices missing. Setting empty languages list.'
                        );
                        setAllDevices([]);
                    }

                    // Gender Filter
                    const genderFilter = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'gender'
                    );
                    if (genderFilter) {
                        const gender = Object.values(genderFilter.choices).map(value => {
                            // console.log(`Gender Value: ${value}`);
                            return value;
                        });
                        // console.log('Gender:', gender);
                        setAllGender(gender);
                    } else {
                        console.warn(
                            'Gender filter or choices missing. Setting empty languages list.'
                        );
                        setAllGender([]);
                    }

                    // Gender Filter
                    const incomeFilter = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'personal-income-euro'
                    );
                    if (incomeFilter) {
                        const income = Object.values(incomeFilter.choices).map(value => {
                            // console.log(`Income Value: ${value}`);
                            return value;
                        });
                        // console.log('Income:', income);
                        setAllIncome(income);
                    } else {
                        console.warn(
                            'Income filter or choices missing. Setting empty languages list.'
                        );
                        setAllIncome([]);
                    }

                    // Company Size Filter
                    const compSize = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'company-size'
                    );
                    if (compSize) {
                        const companySize = Object.values(compSize.choices).map(value => {
                            // console.log(`Income Value: ${value}`);
                            return value;
                        });
                        // console.log('Income:', companySize);
                        setAllCompanySize(companySize);
                    } else {
                        console.warn(
                            'Income filter or choices missing. Setting empty languages list.'
                        );
                        setAllCompanySize([]);
                    }

                    // Education  Filter
                    const education = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'highest-education-level-completed'
                    );
                    if (education) {
                        const edu = Object.values(education.choices).map(value => {
                            // console.log(`Income Value: ${value}`);
                            return value;
                        });
                        // console.log('Income:', edu);
                        setAllEducation(edu);
                    } else {
                        console.warn(
                            'Income filter or choices missing. Setting empty languages list.'
                        );
                        setAllEducation([]);
                    }

                    // Skills Filter
                    const skills = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'knowledge-of-software-development-techniques'
                    );
                    if (skills) {
                        const skill = Object.values(skills.choices).map(value => {
                            // console.log(`Income Value: ${value}`);
                            return value;
                        });
                        // console.log('Income:', skill);
                        setAllSkills(skill);
                    } else {
                        console.warn(
                            'Income filter or choices missing. Setting empty languages list.'
                        );
                        setAllSkills([]);
                    }

                    // Jobs Filter
                    const jobs = data.prolific_response_filter.results.find(
                        f => f.filter_id === 'employment-sector-within-business-management-and-administration'
                    );
                    if (jobs) {
                        const job = Object.values(jobs.choices).map(value => {
                            console.log(`Income Value: ${value}`);
                            return value;
                        });
                        console.log('Income:', job);
                        setAllJobs(job);
                    } else {
                        console.warn(
                            'Income filter or choices missing. Setting empty languages list.'
                        );
                        setAllJobs([]);
                    }

                } else {
                    console.error('Filters array is undefined or not an array');
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleJobCheckboxChange = (size, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedJob((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedJobKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedJobKeys => selectedJobKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleEducationCheckboxChange = (size, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowJobDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedEducation((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedEducationKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedEducationKeys => selectedEducationKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleSkillsCheckboxChange = (size, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowJobDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedSkills((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedSkillsKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedSkillsKeys => selectedSkillsKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleComapnySizeSelect = (size, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowJobDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedCompanySize((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedCompanySizeKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedCompanySizeKeys => selectedCompanySizeKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleIncomeCheckboxChange = (income, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowJobDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedIncome((prev) =>
            prev.includes(income)
                ? prev.filter((item) => item !== income) // Remove if already selected
                : [...prev, income] // Add if not selected
        );
        setSelectedIncomeKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedIncomeKeys => selectedIncomeKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleLanguageCheckboxChange = (language, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowJobDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedLanguages((prev) =>
            prev.includes(language)
                ? prev.filter((item) => item !== language) // Remove if already selected
                : [...prev, language] // Add if not selected
        );
        setSelectedLanguageKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedLanguageKeys => selectedLanguageKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleCheckboxChange = (country, key) => {
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedCountries((prev) =>
            prev.includes(country)
                ? prev.filter((item) => item !== country)
                : [...prev, country]
        );
        // setSelectedCountrtKeys(prev => {
        //     if (prev.includes(key)) {
        //         return prev.filter(selectedCountryKeys => selectedCountryKeys !== key);
        //     } else {
        //         return [...prev, key];
        //     }
        // });
        setSelectedCountrtKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedCountryKeys => selectedCountryKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleGenderSelect = (gender, key) => {
        setShowDropdown(false);
        setShowJobDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowDeviceDropdown(false);
        setSelectedGender((prev) => {
            if (prev.includes(gender)) {
                return prev.filter((item) => item !== gender); // Remove gender if already selected
            } else {
                return [...prev, gender]; // Add gender to selected list
            }
        });
        setSelectedGenderKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedGenderKeys => selectedGenderKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };
    const handleDeviceSelect = (device) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowIndustryDropdown(false);
        setShowJobDropdown(false);
        setSelectedDevice((prev) => {
            if (prev.includes(device)) {
                return prev.filter((item) => item !== device);
            } else {
                return [...prev, device];
            }
        });
    };

    // const handleAgeChange = (values) => {
    //     setAgeRange(values);
    // };


    const handleIndustrySelect = (industry, key) => {
        setShowDropdown(false);
        setShowGenderDropdown(false);
        setShowLanguageDropdown(false);
        setShowCompanySizeDropdown(false);
        setShowEducationDropdown(false);
        setShowSkillsDropdown(false);
        setShowIncomeDropdown(false);
        setShowDeviceDropdown(false);
        setShowJobDropdown(false);
        setSelectedIndustry((prev) => {
            if (prev.includes(industry)) {
                return prev.filter((item) => item !== industry);
            } else {
                return [...prev, industry];
            }
        });
        setSelectedIndustryKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedIndustryKeys => selectedIndustryKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleIncreaseResponse = () => {
        if (quantity < 100) {
            setQuantity(quantity + 1);
        }
    };

    const handleDecreaseResponse = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const handleIncreaseIncentive = () => {
        if (incentive < 100) {
            setIncentive(incentive + 5);
        }
    };

    const handleDecreaseIncentive = () => {
        if (incentive > 5) {
            setIncentive(incentive - 5);
        }
    };

    const inputRef = useRef(null);
    const continuePanelBtn = async () => {
        if (!title) {
            setTitleError(true);
            inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            setTimeout(() => {
                setModalContent({
                    title: 'Title Required',
                    message: 'Please enter the title of your study!',
                });
                setModalOpen(true);
                setButtonText('Create');
            }, 1000);// Show error for missing title
            return;
        }

        setTitleError(false); // Clear the error if the title is valid

        setButtonText('Creating...');

        const data_toProlific = {
            title: title,
            response: quantity,
            link: link,
            device_compatibility: selectedDevice.length > 0 ? selectedDevice.map(device => device.toLowerCase()) : [],
            country: selectedCountrtKeys.length > 0 ? selectedCountrtKeys : [],
            gender: selectedGenderKeys.length > 0 ? selectedGenderKeys : [],
            language: selectedLanguageKeys.length > 0 ? selectedLanguageKeys : [],
            education: selectedEducationKeys.length > 0 ? selectedEducationKeys : [],
            skills: selectedSkillsKeys.length > 0 ? selectedSkillsKeys : [],
            income: selectedIncomeKeys.length > 0 ? selectedIncomeKeys : [],
            industry: selectedIndustryKeys.length > 0 ? selectedIndustryKeys : [],
            size: selectedCompanySizeKeys.length > 0 ? selectedCompanySizeKeys : [],
            job: selectedJobKeys.length > 0 ? selectedJobKeys : [],
        };

        const postToSecondAPI = async () => {
            try {
                const response = await fetch(`${domain}/duonut/prolific_studies`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data_toProlific),
                });
                const responseData = await response.json();
                if (response.status === 200) {
                    console.log("Data submitted to the second API successfully!", responseData);
                    return { success: true, data: responseData };
                } else {
                    const errorData = await response.json();
                    console.error("Error submitting to the second API:", errorData);
                    return false;
                }
            } catch (error) {
                console.error("Error in second API:", error);
                return false;
            }
        };

        // First API call
        const postToFirstAPI = async (data_toDatabase) => {
            try {
                const token = localStorage.getItem("access");
                const response = await fetch(`${domain}/duonut/duonut_prolific_data`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(data_toDatabase),
                });

                const responseData = await response.json(); // Get the response body
                console.log("Response Data:", responseData);

                if (response.ok) {  // Checks if status is 200 OK
                    if (responseData.status === 201) {  // Check the backend response content
                        console.log("Data submitted successfully!");
                        getUserDetails((err, userDetails) => {
                            if (err) return;
                            dispatch(updateTotalCredit(userDetails.data.total_credit));
                        });
                        return true;
                    } else {
                        console.log(`Error: ${responseData.status || "Submission failed"}`);
                        return false;
                    }
                } else {
                    console.log(`Error: ${responseData.error || "Submission failed"}`);
                    return false;
                }
            } catch (error) {
                console.error("Error in first API:", error);
                return false;
            }
        };

        // Execute the second API first
        try {
            // if (userAccountType === 'free') {
            //     setTimeout(() => {
            //         setModalContent({
            //             title: 'Upgrade Required',
            //             message: 'Please upgrade your account to use the credit for your study!',
            //         });
            //         setModalOpen(true);
            //         setButtonText('Create');
            //     }, 1000);
            // } 
            if (total_credit > totalCost) {
                // First, check if the second API is successful
                const response = await postToSecondAPI();

                if (response.success) {
                    const data_toDatabase = {
                        title,
                        response: quantity,
                        type: type,
                        incentive: incentive,
                        cost: totalCost,
                        project_id: response.data.prolific_study.id,
                        project_status: response.data.prolific_study.status,
                        survey_link: link,
                        country: selectedCountries,
                        gender: selectedGender,
                        language: selectedLanguages,
                        education: selectedEducation,
                        skills: selectedSkills,
                        job_title: selectedJob,
                        income: selectedIncome,
                        industry: selectedIndustry,
                        supported_device: selectedDevice,
                        company_size: selectedCompanySize,
                    };
                    // If the second API is successful, then call the first API
                    const firstAPISuccess = await postToFirstAPI(data_toDatabase);

                    if (firstAPISuccess) {
                        setTimeout(() => {
                            setModalContent({
                                title: 'Success',
                                message: 'Project has been created successfully',
                                link: '/aidata?company=1&selected=6',
                                button_message: "View Project"
                            });
                            setModalOpen(true);
                            setButtonText('Create');
                        }, 1000);

                    } else {
                        setTimeout(() => {
                            setModalContent({
                                title: 'Failed',
                                message: 'Failed to create your study. Please try again.',
                                link: null,
                                button_message: "OK"
                            });
                            setModalOpen(true);
                            setButtonText('Create');
                        }, 1000);
                    }
                } else {
                    setTimeout(() => {
                        setModalContent({
                            title: 'Failed',
                            message: 'Please select all the fields to create your study.',
                            link: null,
                            button_message: "OK"
                        });
                        setModalOpen(true);
                        setButtonText('Create');
                    }, 1000);
                }
                setModalOpen(true);
            }
            else {
                setTimeout(() => {
                    setModalContent({
                        title: 'Insufficient Credits',
                        message: `You don't have enough credits to create a study. Please purchase ${totalCost} credits.`,
                        link: '/aidata?company=1&selected=5',
                        button_message: "Purchase Credits"
                    });
                    setModalOpen(true);
                    setButtonText('Create');
                }, 1000);
            }
        } catch (error) {
            setTimeout(() => {
                setModalContent({
                    title: 'Error',
                    message: 'An unexpected error has occurred. Please try again.',
                    link: null,
                    button_message: "OK"
                });
                setModalOpen(true);
                setButtonText('Create');
            }, 1000);
            setModalOpen(true);
            console.error("Error executing API calls:", error);
        }
    };

    const handleOutsideClick = (event) => {
        // Close the country dropdown
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
        if (genderDropdownRef.current && !genderDropdownRef.current.contains(event.target)) {
            setShowGenderDropdown(false);
        }
        if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
            setShowLanguageDropdown(false);
        }
        if (educationDropdownRef.current && !educationDropdownRef.current.contains(event.target)) {
            setShowEducationDropdown(false);
        }
        if (skillDropdownRef.current && !skillDropdownRef.current.contains(event.target)) {
            setShowSkillsDropdown(false);
        }
        if (jobDropdownRef.current && !jobDropdownRef.current.contains(event.target)) {
            setShowJobDropdown(false);
        }
        if (industryDropdownRef.current && !industryDropdownRef.current.contains(event.target)) {
            setShowIndustryDropdown(false);
        }
        if (incomeDropdownRef.current && !incomeDropdownRef.current.contains(event.target)) {
            setShowIncomeDropdown(false);
        }
        if (deviceDropdownRef.current && !deviceDropdownRef.current.contains(event.target)) {
            setShowDeviceDropdown(false);
        }
        if (companySizeDropdownRef.current && !companySizeDropdownRef.current.contains(event.target)) {
            setShowCompanySizeDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <>
            <div
                // className="col-lg-6 col-md-6 col-sm-12"
                style={{
                    maxHeight: "550px",
                    overflowY: "auto",
                    // borderBottom: "1px solid #ccc"
                }}>
                <form ref={formRef}>
                    <div
                        style={{ textAlign: "left", width: "98%" }}>
                        <div className="input-title" style={{ width: "90%", border: titleError ? "1px solid red" : "1px solid #ccc", background: "#cccccc0f", padding: "15px 5px 15px 10px", marginTop: "30px", borderRadius: "5px" }}>
                            <input
                                ref={inputRef}
                                placeholder="Enter the title of your study"
                                style={{ background: "transparent", fontSize: "15px", width: "100%", }}
                                value={title}
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={() => setTitleError(!title)}
                            />
                            {titleError && (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "15px",
                                        display: "block",
                                        width: "100%",
                                        textAlign: "right",
                                    }}
                                >
                                    Title is required.
                                </span>
                            )}
                        </div>
                        <div className="responses">
                            <h3 style={{ textAlign: "left", }}>Responses</h3>
                            <div className=""
                                style={{ display: "flex", flexDirection: "column", marginLeft: "30px", alignItems: "flex-end" }}>
                                <div
                                    style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", display: "flex", justifyContent: "space-between", width: "80px", height: "30px" }}>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        min="1"
                                        max="300"
                                        value={quantity}
                                        style={{ width: '40px', textAlign: 'center' }}
                                        readOnly
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseResponse}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseResponse}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="type-div">
                                                    <div className="type-selector-container" style={{ marginTop: "20px", width: "90%" }}>
                                                        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>Type</h3><br />
                                                        <div className="type-options">
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id="b2b"
                                                                    name="type"
                                                                    value="B2B"
                                                                    checked={type === "B2B"}
                                                                    onChange={() => setType("B2B")}
                                                                    style={{ border: '2px solid #007BFF', }}
                                                                />
                                                                &nbsp; <label htmlFor="b2b">Industry Professionals (B2B)</label><br />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id="b2c"
                                                                    name="type"
                                                                    value="B2C"
                                                                    checked={type === "B2C"}
                                                                    onChange={() => setType("B2C")}
                                                                />
                                                                &nbsp; <label htmlFor="b2c">General Population (B2C)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                        <div className="cost"
                            style={{ textAlign: "left", width: "90%" }}>
                            <div>
                                <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Incentive</label>
                                <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", display: "flex", justifyContent: "space-between", width: "80px", height: "30px" }}>
                                    <input
                                        type="number"
                                        id="incentive"
                                        name="incentive"
                                        min="5"
                                        max="150"
                                        value={incentive}
                                        style={{ width: '40px', textAlign: 'center' }}
                                        readOnly
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseIncentive}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseIncentive}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                                <p style={{ color: "lightgret", fontSize: "13px", marginTop: "5px", textAlign: "left" }}>
                                    We recommend $10 ($1 per min)
                                    {/* {type === "B2B" ? "We recommend $30+($3 per min for B2B)" : "We recommend $10+($1 per min for B2C)"} */}
                                </p>
                            </div>
                            <div>
                                <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Cost : ${totalCost.toFixed(2)}</label>
                                <p style={{ color: "lightgret", fontSize: "13px", marginTop: "5px", textAlign: "left" }}>
                                    ({quantity} completes) x (${incentive} incentive + $5 recuriting cost)
                                    {/* {type === "B2B" ? "6" : "4"} {type} recruiting cost) */}
                                </p>
                            </div>
                        </div>
                        {/* <div
                                                    style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", margin: "10px", width: "100%", paddingTop: "10px", borderTop: "1px solid #dbd9e1" }}>

                                                     <div className="age-selector-container" style={{ marginTop: '0', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div>
                                                        <label style={{ fontWeight: "bold" }}>Age Range</label>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px", width: "80%" }}>
                                                        <span style={{ fontSize: "14px", color: "grey", marginRight: "10px" }}>{ageRange[0]}</span> {/* Left value 
                                                        <ReactSlider
                                                            className="horizontal-slider"
                                                            thumbClassName="thumb"
                                                            trackClassName="track"
                                                            min={18}
                                                            max={100}
                                                            step={1}
                                                            value={ageRange}
                                                            onChange={handleAgeChange}
                                                            renderTrack={(props, state) => {
                                                                const { index } = state; // Track index (0 = left, 1 = middle, 2 = right)
                                                                const isSelectedRange = index === 1;
                                                                return (
                                                                    <div
                                                                        {...props}
                                                                        style={{
                                                                            ...props.style,
                                                                            backgroundColor: isSelectedRange ? "#007BFF" : "#B0D4FF",
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        <span style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>{ageRange[1]}</span> 
                                                    </div>
                                                </div> */}
                        <div className="country-selector-container"
                            ref={dropdownRef}
                            style={{ marginTop: "0", width: "90%" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDropdown(!showDropdown);
                            }}
                        >
                            <label style={{ fontWeight: "bold" }}>Country</label>
                            <div className="dropdown-field" style={{ marginTop: "10px", fontSize: "16px" }}>
                                <span>
                                    {selectedCountries.length > 0
                                        ? selectedCountries.join(", ")
                                        : "Any"}
                                </span>
                                &nbsp;
                                {showDropdown ? (
                                    <i className="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                ) : (
                                    <i className="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                )}
                            </div>
                            {showDropdown && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}>
                                    {allcountry.map((item, i) => (
                                        <label key={i} className="dropdown-item">
                                            <input
                                                type="checkbox"
                                                checked={selectedCountries.includes(item)}
                                                onChange={() => handleCheckboxChange(item, i)}
                                                style={{ marginRight: "10px" }}
                                            />
                                            {item}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="gender-selector-container"
                            ref={genderDropdownRef}
                            style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowGenderDropdown(!showGenderDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Gender</label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedGender.length > 0
                                        ? selectedGender.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showGenderDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                }
                            </div>
                            {showGenderDropdown && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}>
                                    {allGender.map((gender, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedGender.includes(gender)}
                                                onChange={() => handleGenderSelect(gender, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {gender}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="language-selector-container"
                            ref={languageDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowLanguageDropdown(!showLanguageDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Languages</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedLanguages.length > 0
                                        ? selectedLanguages.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showLanguageDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {showLanguageDropdown && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }} >
                                    {allLanguages.map((lang, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedLanguages.includes(lang)}
                                                onChange={() => handleLanguageCheckboxChange(lang, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {lang}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="education-selector-container"
                            ref={educationDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowLanguageDropdown(!showLanguageDropdown);
                            }}>

                            <label style={{ fontWeight: "bold" }}>Education</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedEducation.length > 0
                                        ? selectedEducation.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showEducationDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {showEducationDropdown && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }} >
                                    {allEducation.map((education, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedEducation.includes(education)}
                                                onChange={() => handleEducationCheckboxChange(education, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {education}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="skills-selector-container"
                            ref={skillDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowSkillsDropdown(!showSkillsDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Skills</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedSkills.length > 0
                                        ? selectedSkills.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showSkillsDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {showSkillsDropdown && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }} >
                                    {allSkills.map((skills, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedSkills.includes(skills)}
                                                onChange={() => handleSkillsCheckboxChange(skills, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {skills}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="job-selector-container"
                            ref={jobDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowJobDropdown(!showJobDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Job Title</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedJob.length > 0
                                        ? selectedJob.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showJobDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {showJobDropdown && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }} >
                                    {allJob.map((job, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedJob.includes(job)}
                                                onChange={() => handleJobCheckboxChange(job, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {job}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="income-selector-container"
                            ref={incomeDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowIncomeDropdown(!showIncomeDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Income</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedIncome.length > 0
                                        ? selectedIncome.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showIncomeDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {showIncomeDropdown && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }} >
                                    {allIncome.map((income, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedIncome.includes(income)}
                                                onChange={() => handleIncomeCheckboxChange(income, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {income}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="industry-selector-container"
                            ref={industryDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowIndustryDropdown(!showIndustryDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Industries</label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedIndustry.length > 0
                                        ? selectedIndustry.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showIndustryDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                }
                            </div>
                            {showIndustryDropdown && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}>
                                    {allIndustry.map((industry, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedIndustry.includes(industry)}
                                                onChange={() => handleIndustrySelect(industry, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {industry}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="devices-selector-container"
                            ref={deviceDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeviceDropdown(!showDeviceDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Supported Devices</label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedDevice.length > 0
                                        ? selectedDevice.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showDeviceDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                }
                            </div>
                            {/* {showDeviceDropdown && (
                                                        <div className="dropdown-menu" style={{ fontSize: "16px" }}>
                                                            {allDevices.map((device, i) => (
                                                                <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedDevice.includes(device)}
                                                                        onChange={() => handleDeviceSelect(device)}
                                                                        style={{ marginRight: '10px' }}
                                                                    />
                                                                    {device}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    )} */}
                            {showDeviceDropdown && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}>
                                    {['Mobile', 'Tablet', 'Desktop'].map((device, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedDevice.includes(device)}
                                                onChange={() => handleDeviceSelect(device)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {device}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="companySize-selector-container"
                            ref={companySizeDropdownRef}
                            style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowCompanySizeDropdown(!showCompanySizeDropdown);
                            }}>
                            <label style={{ fontWeight: "bold" }}>Company Size</label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedCompanySize.length > 0
                                        ? selectedCompanySize.join(', ')
                                        : 'Any'}
                                </span> &nbsp;
                                {showCompanySizeDropdown ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                }
                            </div>
                            {showCompanySizeDropdown && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}>
                                    {allCompanySize.map((size, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedCompanySize.includes(size)}
                                                onChange={() => handleComapnySizeSelect(size, i)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {size}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        {/* </div> */}
                    </div>
                </form >
            </div >
            {/* <div>
                                        <h3>Screener Questions</h3>
                                    </div> */}
            {/* <div className="col-lg-6 col-md-6 col-sm-12"
                                        style={{
                                            background: "#0000000d", textAlign: "left",
                                            // maxWidth: "700px", borderBottom: "1px solid #ccc"
                                        }}
                                    >
                                        <div style={{ marginTop: "2vh", width: "90%" }}>
                                            {/* Email Input 
                                            <div style={{ marginBottom: "1vh" }}>
                                                <label htmlFor="email" style={{ fontWeight: "bold" }}>Email:</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "5px",
                                                        marginTop: "5px",
                                                    }}
                                                />
                                            </div>

                                            {/* Phone Number Input 
                                            <div style={{ marginBottom: "1vh" }}>
                                                <label htmlFor="phone" style={{ fontWeight: "bold" }}>Phone Number:</label>
                                                <input
                                                    type="tel"
                                                    id="phone"
                                                    placeholder="Enter your phone number"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "5px",
                                                        marginTop: "5px",
                                                    }}
                                                />
                                            </div>
                                            {/* Summary Section 
                                            <div style={{
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                padding: "10px",
                                                marginTop: "2vh",
                                                backgroundColor: "#f9f9f9",
                                                width: "100%"
                                            }}>
                                                <h3 style={{ marginBottom: "1vh", paddingBottom: "10px", borderBottom: "1px solid lightgrey" }}>Order Summary</h3>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Gender:</strong> {selectedGender.length > 0 ? selectedGender.join(", ") : "Any"}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Age:</strong> {ageRange[0]} - {ageRange[1]}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Country:</strong> {selectedCountries.length > 0 ? selectedCountries.join(", ") : "Any"}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Languages:</strong> {selectedLanguages.length > 0 ? selectedLanguages.join(",") : "Any"}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Industries:</strong> {selectedIndustry.length > 0 ? selectedIndustry.join(", ") : "Any"}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Income:</strong> {selectedIncome.length > 0 ? selectedIncome.join(", ") : "Any"}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Comapny Size:</strong> {selectedCompanySize.length > 0 ? selectedCompanySize.join(", ") : "Any"}</p>
                                                <p style={{ margin: "5px", fontSize: "16px", color: "#41484c" }}><strong>Supported Devices:</strong> {selectedDevice.length > 0 ? selectedDevice.join(", ") : "Any"}</p>
                                            </div>
                                        </div>
                                    </div> */}

            <div>
                <button className='QRC1Continuebtn' onClick={() => resetBtn()}>Reset</button>
                <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                    onClick={() => continuePanelBtn()}> {buttonText}</button>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    title={modalContent.title}
                    message={modalContent.message}
                    link={modalContent.link}
                    button_message={modalContent.button_message}
                />
            </div>
        </>
    );
}
export default ProlificPanel;