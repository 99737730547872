import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Payment from "./CreditPayment/Payment";
import { updateLogoutModalActive } from "../../redux/slices/formDetails";
import { useNavigate } from "react-router-dom";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { useDispatch } from "react-redux";

const Credit = () => {
    const { isLoggedIn, userAccountType, userProAccess, total_credit } = useSelector((state) => state.user);
    const [credit, setCredit] = useState(10);
    const [showPayment, setShowPayment] = useState(false);  // or set the correct logic based on your requirements
    const dispatch = useDispatch();
    const [selectedPanelTab, setselectedPanelTab] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(false); // State to control sidebar collapse
    const navigate = useNavigate();
    const [openLogout, setopenLogout] = useState(false);

    const handelClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);
    useOutsideAlerter(logoutRef, handelClose);

    const handleIncreaseCredit = () => {
        if (credit < 2000) {
            setCredit(credit + 5);
        }
    };

    const handleDecreaseCredit = () => {
        if (credit > 5) {
            setCredit(credit - 5);
        }
    };

    const handleAddCredit = () => {
        setShowPayment(true);
    };
    const handleClosePayment = () => {
        setShowPayment(false);
    };

    return (
        <div className='duonutContainer' style={{ display: "flex" }}>
            <div className="sidebar-toggle" onClick={() => setIsCollapsed(!isCollapsed)} style={{ paddingTop: "5vh" }}>
                <i className={`fa ${isCollapsed ? 'fa-times' : 'fa-bars'}`} style={{ color: "#3184c9" }} aria-hidden="true"></i>
            </div>

            {/* Sidebar */}
            <div className={`AIDPN1leftContainer ${isCollapsed ? 'collapsed' : ''}`} style={{ paddingTop: "10vh", fontSize: "17px", minWidth: "15%" }}>
                {/* <div style={{ marginBottom: '1rem', display: "flex", justifyContent: "center" }}>
                  <img style={{ width: "auto", height: "4rem", borderRadius: "10px", cursor: "pointer" }}
                    onClick={() => window.location = "/home"}
                    src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />
                </div> */}
                {/* {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                            (aiData1?.length < 5 && userAccountType === "plus") || (aiData1?.length < 20 && userAccountType === "enterprise")) && <div className='AIDPN1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                                <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                            </div>} */}


                <div className='AIDPN1tabName' onClick={() => navigate("/profile")}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    Account Settings
                </div>
                {/* <div className='AIDPN1tabName' onClick={() => { dispatch(toggleUserSettingModalDuonut(true)); }}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                  Duonut Mapping
                </div> */}
                {/* {isDuonut &&  */}
                <div className='AIDPN1tabName' onClick={() => navigate("/myduonuts")}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    My Duonuts
                </div>
                <div className='AIDPN1tabName' onClick={() => navigate("/credit")}
                    style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    Credits
                </div>
                <div className='AIDPN1tabName' onClick={() => navigate("/paneldata")}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                    Panels
                </div>

                {/* <div className='AIDPN1tabName' onClick={() => navigate("/aidata?selected=5")}
                // style={{ fontWeight: "600", backgroundColor: "#455c6e" }}
                >
                  Credits
                </div> */}
                {/* } */}
                <div ref={logoutRef} style={{
                    marginLeft: "10px", display: "flex", justifyContent: "center", textAlign: "center", position: "absolute", bottom: "10px", flexDirection: "column"
                }}>
                    {/* <div className='AIDPN1tabName' onClick={() => window.open("https://duonut.com/contact", "_blank")}>
                    Support
                  </div> */}
                    <div style={{ cursor: "pointer" }} onClick={() => setopenLogout(!openLogout)}>

                        <div style={{ fontWeight: "600", paddingBottom: "4px" }}>
                            {localStorage.getItem("userName") !== "null" ? localStorage.getItem("userName") : ""} &nbsp; &nbsp; &nbsp;
                            {openLogout ? (
                                <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setopenLogout(false);
                                    }}
                                ></i>
                            ) : (
                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                            )}
                        </div>
                        <div style={{ fontSize: "0.8rem" }}>{localStorage.getItem("userEmail")}</div>
                    </div>
                    {openLogout && (
                        <div className='open-logout'>
                            <ul style={{ textDecoration: "none", width: "100%" }}>
                                <li>
                                    <a href="https://duonut.com/pricing#pricing_container_list" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                        <i className="fa fa-credit-card" aria-hidden="true"></i>&nbsp; Pricing
                                    </a>
                                </li>
                                <li>
                                    <a href="https://duonut.com/contact" target="_blank" style={{ textDecoration: "none", color: "white", display: "block", width: "100%" }}>
                                        <i class="fa fa-question-circle" aria-hidden="true"></i>&nbsp; Support
                                    </a>
                                </li>
                                {/* Logout */}
                                <li onClick={() => dispatch(updateLogoutModalActive(true))}>
                                    <i className="fa fa-sign-out" aria-hidden="true"></i> &nbsp; Log Out
                                </li>
                            </ul>
                        </div>
                    )}
                </div>

            </div>

            <div style={{
                display: "flex", flexDirection: "column", width: "90%", paddingTop: "8vh"
            }}>
                <div className='AIDPN1container2'>
                    < div style={{ background: "#cccccc1f", borderRadius: "5px", padding: "15px 25px" }}>
                        <h2 style={{ margin: "auto", paddingBottom: "1rem", textAlign: "center" }}>Credits</h2>
                        <div>
                            <p style={{ fontSize: "14px" }}>Your Acoount has</p>
                            <h3>${total_credit} in panel credits</h3>
                            <p style={{ fontSize: "14px", color: "#6e777c" }}>Panel credits are required to recruit respondents for your projects.</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                <div
                                    style={{
                                        border: "1px solid #ccc", borderRadius: "5px", padding: "5px",
                                        display: "flex", justifyContent: "space-between", width: "80px",
                                        height: "30px", width: "40%", margin: "20px 0px"
                                    }}>
                                    <input
                                        type="number"
                                        id="credit"
                                        name="credit"
                                        min="5"
                                        max="2000"
                                        value={credit}
                                        onChange={(e) => setCredit(Math.min(Math.max(Number(e.target.value), 5), 2000))}
                                        style={{ width: '40px', textAlign: 'center' }}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseCredit}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseCredit}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {/* {userAccountType === "free" ? (
                                                      <a href='https://duonut.com/pricing#pricing_container_list' target='_blank'>
                                                          <button style={{ border: "1px solid #ccc", borderRadius: "5px", background: "#ccc", padding: "12px", cursor: "pointer" }}>Add Credit <i class="fa fa-lock" aria-hidden="true"></i></button>
                                                      </a>
                                                  ) : ( */}
                                    {/* (userAccountType === "pro" || userAccountType === "plus" || userAccountType === "enterprise") && ( */}
                                    <button style={{ border: "1px solid #ccc", borderRadius: "5px", background: "#ccc", padding: "12px", cursor: "pointer" }} onClick={handleAddCredit}>Add Credit</button>
                                    {/* ) */}
                                    {/* )} */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#cccccc1f", borderRadius: "5px", marginTop: "25px" }}>
                        <h2 style={{ margin: "auto", padding: "2rem 0rem", textAlign: "center" }}>Previous Transactions</h2>
                        <div style={{ padding: "0px 10px" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse", margin: "1rem 0" }}>
                                <thead>
                                    <tr style={{ background: "#f4f4f4", textAlign: "left" }}>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>No.</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Name</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Message</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Amount</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "1rem", border: "1px solid #ddd" }}>1</td>
                                        <td style={{ padding: "1rem", border: "1px solid #ddd" }}>Sneha Pandit</td>
                                        <td style={{ padding: "1rem", border: "1px solid #ddd" }}>Payment for Prolfic</td>
                                        <td style={{ padding: "1rem", border: "1px solid #ddd" }}>$100</td>
                                        <td style={{ padding: "1rem", border: "1px solid #ddd" }}>Completed</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {showPayment && (
                        <div className="credit-overlay">
                            <div className="credit-container">
                                <button
                                    className="credit-close-button"
                                    onClick={handleClosePayment}
                                >
                                    ×
                                </button>
                                <Payment credit={credit} />
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </div >

    );
}

export default Credit;