import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import videoPreview from '../../assets/Images/video-preview.png';
import { useSelector, useDispatch } from "react-redux";

const Video = ({
  id,
  elementAttr: {
    url, border, margin, padding, type,
  },
}) => {
  const location = useLocation();
  const [videoId, setVideoId] = useState("");
  const { viewType } = useSelector((state) => state.duonutSettings);

  useEffect(() => {
    if (url && !type) {
      var videoID = url.match("^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?\.com|youtu\.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)")[1];
      setVideoId(videoID);
    }
  }, [url]);

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
      playsinline: 1
    }
  };

  const clickVideo = (e) => {
    e.preventDefault()
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", width: '100%', height: '100%', position: "relative" }}
      onClick={(e) => clickVideo(e)}>
      {url && !type ? <><YouTube
        videoId={videoId}
        id='player'
        containerClassName="embed embed-youtube"
        opts={opts}
        // className="S1youtube"
        style={{
          maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%',
          border, margin, padding
        }}
      />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: (location.pathname).includes("/duonut/") ? "none" : 'block'
            // cursor: 'not-allowed'
          }}
          onClick={clickVideo}
        /></>
        : url ? <><video
          id="vid"
          style={{
            maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%',
            border, margin, padding
          }}
          playsInline
          controls
          key={id}>
          <source src={url} type="video/mp4" />
        </video>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              display: (location.pathname).includes("/duonut/") ? "none" : 'block'
              // cursor: 'not-allowed'
            }}
            onClick={clickVideo}
          /></>
          : <img src={videoPreview} style={{
            maxWidth: '100%', maxHeight: '100%', objectFit: "cover",
            cursor: (location.pathname).includes("/duonut/") ? "default" : 'default',
            border, margin, padding
          }} alt="" />}
    </div>
  );
}

export default Video;
