import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function Ddescription2FontWeight() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [description2fontWeight, setDescription2FontWeight] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        styles?.description2FontWeight ?
            setDescription2FontWeight(styles.description2FontWeight) : setDescription2FontWeight(400)
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity]])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleFontWeightChange = e => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    description2FontWeight: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>Description2 Font Weight</p>
            <select value={description2fontWeight} className='text_editor_dropdown' onChange={e => handleFontWeightChange(e)} >
                {description2FontWeights.map(description2fontWeight =>
                    <option key={description2fontWeight} value={description2fontWeight} > {description2fontWeight} </option>
                )}
            </select>
            {/* <img src='/assets/icons/down-arrow.svg' className='select-icon' /> */}
        </div>
    )
}

const description2FontWeights = [
    100, 200, 300, 400, 500, 600, 700, 800
]